import * as actionTypes from "./actionType";
import { updateObject } from "../utility";


export const initialState = {
	error: null,
	loading: null,
	faq:[],
	faqDetail:null,
}






const faqGetStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const faqGetSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		faq: action.faq,
	});
};
const faqGetFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const faqPostStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const faqPostSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		faqDetail: action.faqDetail,
	});
};
const faqPostFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const faqEditStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const faqEditSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		faqDetail: action.faqDetail,
	});
};
const faqEditFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const faqDetailStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const faqDetailSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		faqDetail: action.faqDetail,
	});
};
const faqDetailFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const faqDeleteStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const faqDeleteSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		faqDetail: action.faqDetail,
		faq: state.faq.filter((item)=>item.id != action.faqDetail.id),
	});
};
const faqDeleteFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const reducer = (state = initialState, action) => { 
	switch (action.type) { 
		case actionTypes.FAQ_GET_START:
			return faqGetStart(state, action);
		case actionTypes.FAQ_GET_SUCCESS:
			return faqGetSuccess(state, action);
		case actionTypes.FAQ_GET_FAIL:
			return faqGetFail(state, action);






		case actionTypes.FAQ_POST_START:
			return faqPostStart(state, action);
		case actionTypes.FAQ_POST_SUCCESS:
			return faqPostSuccess(state, action);
		case actionTypes.FAQ_POST_FAIL:
			return faqPostFail(state, action);






		case actionTypes.FAQ_EDIT_START:
			return faqEditStart(state, action);
		case actionTypes.FAQ_EDIT_SUCCESS:
			return faqEditSuccess(state, action);
		case actionTypes.FAQ_EDIT_FAIL:
			return faqEditFail(state, action);






		case actionTypes.FAQ_DETAIL_START:
			return faqDetailStart(state, action);
		case actionTypes.FAQ_DETAIL_SUCCESS:
			return faqDetailSuccess(state, action);
		case actionTypes.FAQ_DETAIL_FAIL:
			return faqDetailFail(state, action);






		case actionTypes.FAQ_DELETE_START:
			return faqDeleteStart(state, action);
		case actionTypes.FAQ_DELETE_SUCCESS:
			return faqDeleteSuccess(state, action);
		case actionTypes.FAQ_DELETE_FAIL:
			return faqDeleteFail(state, action);



		default:
			return state;
	}
};
export default reducer;
