export const TEAM_POST_START = "TEAM_POST_START"
export const TEAM_POST_SUCCESS = "TEAM_POST_SUCCESS"
export const TEAM_POST_FAIL = "TEAM_POST_FAIL"


export const TEAM_GET_START = "TEAM_GET_START"
export const TEAM_GET_SUCCESS = "TEAM_GET_SUCCESS"
export const TEAM_GET_FAIL = "TEAM_GET_FAIL"


export const TEAM_DETAIL_START = "TEAM_DETAIL_START"
export const TEAM_DETAIL_SUCCESS = "TEAM_DETAIL_SUCCESS"
export const TEAM_DETAIL_FAIL = "TEAM_DETAIL_FAIL"


export const TEAM_EDIT_START = "TEAM_EDIT_START"
export const TEAM_EDIT_SUCCESS = "TEAM_EDIT_SUCCESS"
export const TEAM_EDIT_FAIL = "TEAM_EDIT_FAIL"


export const TEAM_DELETE_START = "TEAM_DELETE_START"
export const TEAM_DELETE_SUCCESS = "TEAM_DELETE_SUCCESS"
export const TEAM_DELETE_FAIL = "TEAM_DELETE_FAIL"


