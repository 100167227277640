import * as actionTypes from './actionType'
import { updateObject } from '../utility'

export const initialState = {
  error: null,
  loading: null,
  popularcourse: [],
  popularcourseDetail: {},
}

const popularcourseGetStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const popularcourseGetSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    popularcourse: action.popularcourse,
  })
}
const popularcourseGetFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const popularcoursePostStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const popularcoursePostSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    popularcourseDetail: action.popularcourseDetail,
  })
}
const popularcoursePostFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const popularcourseEditStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const popularcourseEditSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    popularcourseDetail: action.popularcourseDetail,
  })
}
const popularcourseEditFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const popularcourseDetailStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const popularcourseDetailSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    popularcourseDetail: action.popularcourseDetail,
  })
}
const popularcourseDetailFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const popularcourseDeleteStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const popularcourseDeleteSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    popularcourseDetail: action.popularcourseDetail,
    popularcourse: state.popularcourse.filter((item) => item.id != action.popularcourseDetail.id),
  })
}
const popularcourseDeleteFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POPULARCOURSE_GET_START:
      return popularcourseGetStart(state, action)
    case actionTypes.POPULARCOURSE_GET_SUCCESS:
      return popularcourseGetSuccess(state, action)
    case actionTypes.POPULARCOURSE_GET_FAIL:
      return popularcourseGetFail(state, action)

    case actionTypes.POPULARCOURSE_POST_START:
      return popularcoursePostStart(state, action)
    case actionTypes.POPULARCOURSE_POST_SUCCESS:
      return popularcoursePostSuccess(state, action)
    case actionTypes.POPULARCOURSE_POST_FAIL:
      return popularcoursePostFail(state, action)

    case actionTypes.POPULARCOURSE_EDIT_START:
      return popularcourseEditStart(state, action)
    case actionTypes.POPULARCOURSE_EDIT_SUCCESS:
      return popularcourseEditSuccess(state, action)
    case actionTypes.POPULARCOURSE_EDIT_FAIL:
      return popularcourseEditFail(state, action)

    case actionTypes.POPULARCOURSE_DETAIL_START:
      return popularcourseDetailStart(state, action)
    case actionTypes.POPULARCOURSE_DETAIL_SUCCESS:
      return popularcourseDetailSuccess(state, action)
    case actionTypes.POPULARCOURSE_DETAIL_FAIL:
      return popularcourseDetailFail(state, action)

    case actionTypes.POPULARCOURSE_DELETE_START:
      return popularcourseDeleteStart(state, action)
    case actionTypes.POPULARCOURSE_DELETE_SUCCESS:
      return popularcourseDeleteSuccess(state, action)
    case actionTypes.POPULARCOURSE_DELETE_FAIL:
      return popularcourseDeleteFail(state, action)

    default:
      return state
  }
}
export default reducer
