export const SERVICE_POST_START = "SERVICE_POST_START"
export const SERVICE_POST_SUCCESS = "SERVICE_POST_SUCCESS"
export const SERVICE_POST_FAIL = "SERVICE_POST_FAIL"


export const SERVICE_GET_START = "SERVICE_GET_START"
export const SERVICE_GET_SUCCESS = "SERVICE_GET_SUCCESS"
export const SERVICE_GET_FAIL = "SERVICE_GET_FAIL"


export const SERVICE_DETAIL_START = "SERVICE_DETAIL_START"
export const SERVICE_DETAIL_SUCCESS = "SERVICE_DETAIL_SUCCESS"
export const SERVICE_DETAIL_FAIL = "SERVICE_DETAIL_FAIL"


export const SERVICE_EDIT_START = "SERVICE_EDIT_START"
export const SERVICE_EDIT_SUCCESS = "SERVICE_EDIT_SUCCESS"
export const SERVICE_EDIT_FAIL = "SERVICE_EDIT_FAIL"


export const SERVICE_DELETE_START = "SERVICE_DELETE_START"
export const SERVICE_DELETE_SUCCESS = "SERVICE_DELETE_SUCCESS"
export const SERVICE_DELETE_FAIL = "SERVICE_DELETE_FAIL"


