export const TESTIMONIALS_POST_START = "TESTIMONIALS_POST_START"
export const TESTIMONIALS_POST_SUCCESS = "TESTIMONIALS_POST_SUCCESS"
export const TESTIMONIALS_POST_FAIL = "TESTIMONIALS_POST_FAIL"


export const TESTIMONIALS_GET_START = "TESTIMONIALS_GET_START"
export const TESTIMONIALS_GET_SUCCESS = "TESTIMONIALS_GET_SUCCESS"
export const TESTIMONIALS_GET_FAIL = "TESTIMONIALS_GET_FAIL"


export const TESTIMONIALS_DETAIL_START = "TESTIMONIALS_DETAIL_START"
export const TESTIMONIALS_DETAIL_SUCCESS = "TESTIMONIALS_DETAIL_SUCCESS"
export const TESTIMONIALS_DETAIL_FAIL = "TESTIMONIALS_DETAIL_FAIL"


export const TESTIMONIALS_EDIT_START = "TESTIMONIALS_EDIT_START"
export const TESTIMONIALS_EDIT_SUCCESS = "TESTIMONIALS_EDIT_SUCCESS"
export const TESTIMONIALS_EDIT_FAIL = "TESTIMONIALS_EDIT_FAIL"


export const TESTIMONIALS_DELETE_START = "TESTIMONIALS_DELETE_START"
export const TESTIMONIALS_DELETE_SUCCESS = "TESTIMONIALS_DELETE_SUCCESS"
export const TESTIMONIALS_DELETE_FAIL = "TESTIMONIALS_DELETE_FAIL"


