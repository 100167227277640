import * as actionTypes from "./actionType";
import { updateObject } from "../utility";


export const initialState = {
	error: null,
	loading: null,
	aboutus:[],
	aboutusDetail:null,
}






const aboutusGetStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const aboutusGetSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		aboutus: action.aboutus,
	});
};
const aboutusGetFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const aboutusPostStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const aboutusPostSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		aboutusDetail: action.aboutusDetail,
	});
};
const aboutusPostFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const aboutusEditStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const aboutusEditSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		aboutusDetail: action.aboutusDetail,
	});
};
const aboutusEditFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const aboutusDetailStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const aboutusDetailSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		aboutusDetail: action.aboutusDetail,
	});
};
const aboutusDetailFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const aboutusDeleteStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const aboutusDeleteSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		aboutusDetail: action.aboutusDetail,
		aboutus: state.aboutus.filter((item)=>item.id != action.aboutusDetail.id),
	});
};
const aboutusDeleteFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const reducer = (state = initialState, action) => { 
	switch (action.type) { 
		case actionTypes.ABOUTUS_GET_START:
			return aboutusGetStart(state, action);
		case actionTypes.ABOUTUS_GET_SUCCESS:
			return aboutusGetSuccess(state, action);
		case actionTypes.ABOUTUS_GET_FAIL:
			return aboutusGetFail(state, action);






		case actionTypes.ABOUTUS_POST_START:
			return aboutusPostStart(state, action);
		case actionTypes.ABOUTUS_POST_SUCCESS:
			return aboutusPostSuccess(state, action);
		case actionTypes.ABOUTUS_POST_FAIL:
			return aboutusPostFail(state, action);






		case actionTypes.ABOUTUS_EDIT_START:
			return aboutusEditStart(state, action);
		case actionTypes.ABOUTUS_EDIT_SUCCESS:
			return aboutusEditSuccess(state, action);
		case actionTypes.ABOUTUS_EDIT_FAIL:
			return aboutusEditFail(state, action);






		case actionTypes.ABOUTUS_DETAIL_START:
			return aboutusDetailStart(state, action);
		case actionTypes.ABOUTUS_DETAIL_SUCCESS:
			return aboutusDetailSuccess(state, action);
		case actionTypes.ABOUTUS_DETAIL_FAIL:
			return aboutusDetailFail(state, action);






		case actionTypes.ABOUTUS_DELETE_START:
			return aboutusDeleteStart(state, action);
		case actionTypes.ABOUTUS_DELETE_SUCCESS:
			return aboutusDeleteSuccess(state, action);
		case actionTypes.ABOUTUS_DELETE_FAIL:
			return aboutusDeleteFail(state, action);



		default:
			return state;
	}
};
export default reducer;
