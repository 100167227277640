export const BRANCH_POST_START = "BRANCH_POST_START"
export const BRANCH_POST_SUCCESS = "BRANCH_POST_SUCCESS"
export const BRANCH_POST_FAIL = "BRANCH_POST_FAIL"


export const BRANCH_GET_START = "BRANCH_GET_START"
export const BRANCH_GET_SUCCESS = "BRANCH_GET_SUCCESS"
export const BRANCH_GET_FAIL = "BRANCH_GET_FAIL"


export const BRANCH_DETAIL_START = "BRANCH_DETAIL_START"
export const BRANCH_DETAIL_SUCCESS = "BRANCH_DETAIL_SUCCESS"
export const BRANCH_DETAIL_FAIL = "BRANCH_DETAIL_FAIL"


export const BRANCH_EDIT_START = "BRANCH_EDIT_START"
export const BRANCH_EDIT_SUCCESS = "BRANCH_EDIT_SUCCESS"
export const BRANCH_EDIT_FAIL = "BRANCH_EDIT_FAIL"


export const BRANCH_DELETE_START = "BRANCH_DELETE_START"
export const BRANCH_DELETE_SUCCESS = "BRANCH_DELETE_SUCCESS"
export const BRANCH_DELETE_FAIL = "BRANCH_DELETE_FAIL"


