import * as actionTypes from './actionType'
import { updateObject } from '../utility'

export const initialState = {
  error: null,
  loading: null,
  testpreprationfaq: [],
  testpreprationfaqDetail: null,
}

const testpreprationfaqGetStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const testpreprationfaqGetSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    testpreprationfaq: action.testpreprationfaq,
  })
}
const testpreprationfaqGetFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const testpreprationfaqPostStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const testpreprationfaqPostSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    testpreprationfaqDetail: action.testpreprationfaqDetail,
  })
}
const testpreprationfaqPostFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const testpreprationfaqEditStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const testpreprationfaqEditSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    testpreprationfaqDetail: action.testpreprationfaqDetail,
  })
}
const testpreprationfaqEditFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const testpreprationfaqDetailStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const testpreprationfaqDetailSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    testpreprationfaqDetail: action.testpreprationfaqDetail,
  })
}
const testpreprationfaqDetailFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const testpreprationfaqDeleteStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const testpreprationfaqDeleteSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    testpreprationfaqDetail: action.testpreprationfaqDetail,
    testpreprationfaq: state.testpreprationfaq.filter(
      (item) => item.id != action.testpreprationfaqDetail.id,
    ),
  })
}
const testpreprationfaqDeleteFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TESTPREPRATIONFAQ_GET_START:
      return testpreprationfaqGetStart(state, action)
    case actionTypes.TESTPREPRATIONFAQ_GET_SUCCESS:
      return testpreprationfaqGetSuccess(state, action)
    case actionTypes.TESTPREPRATIONFAQ_GET_FAIL:
      return testpreprationfaqGetFail(state, action)

    case actionTypes.TESTPREPRATIONFAQ_POST_START:
      return testpreprationfaqPostStart(state, action)
    case actionTypes.TESTPREPRATIONFAQ_POST_SUCCESS:
      return testpreprationfaqPostSuccess(state, action)
    case actionTypes.TESTPREPRATIONFAQ_POST_FAIL:
      return testpreprationfaqPostFail(state, action)

    case actionTypes.TESTPREPRATIONFAQ_EDIT_START:
      return testpreprationfaqEditStart(state, action)
    case actionTypes.TESTPREPRATIONFAQ_EDIT_SUCCESS:
      return testpreprationfaqEditSuccess(state, action)
    case actionTypes.TESTPREPRATIONFAQ_EDIT_FAIL:
      return testpreprationfaqEditFail(state, action)

    case actionTypes.TESTPREPRATIONFAQ_DETAIL_START:
      return testpreprationfaqDetailStart(state, action)
    case actionTypes.TESTPREPRATIONFAQ_DETAIL_SUCCESS:
      return testpreprationfaqDetailSuccess(state, action)
    case actionTypes.TESTPREPRATIONFAQ_DETAIL_FAIL:
      return testpreprationfaqDetailFail(state, action)

    case actionTypes.TESTPREPRATIONFAQ_DELETE_START:
      return testpreprationfaqDeleteStart(state, action)
    case actionTypes.TESTPREPRATIONFAQ_DELETE_SUCCESS:
      return testpreprationfaqDeleteSuccess(state, action)
    case actionTypes.TESTPREPRATIONFAQ_DELETE_FAIL:
      return testpreprationfaqDeleteFail(state, action)

    default:
      return state
  }
}
export default reducer
