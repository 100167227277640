import * as actionTypes from "./actionType";
import { updateObject } from "../utility";


export const initialState = {
	error: null,
	loading: null,
	testimonials:[],
	testimonialsDetail:null,
}






const testimonialsGetStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const testimonialsGetSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		testimonials: action.testimonials,
	});
};
const testimonialsGetFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const testimonialsPostStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const testimonialsPostSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		testimonialsDetail: action.testimonialsDetail,
	});
};
const testimonialsPostFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const testimonialsEditStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const testimonialsEditSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		testimonialsDetail: action.testimonialsDetail,
	});
};
const testimonialsEditFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const testimonialsDetailStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const testimonialsDetailSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		testimonialsDetail: action.testimonialsDetail,
	});
};
const testimonialsDetailFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const testimonialsDeleteStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const testimonialsDeleteSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		testimonialsDetail: action.testimonialsDetail,
		testimonials: state.testimonials.filter((item)=>item.id != action.testimonialsDetail.id),
	});
};
const testimonialsDeleteFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const reducer = (state = initialState, action) => { 
	switch (action.type) { 
		case actionTypes.TESTIMONIALS_GET_START:
			return testimonialsGetStart(state, action);
		case actionTypes.TESTIMONIALS_GET_SUCCESS:
			return testimonialsGetSuccess(state, action);
		case actionTypes.TESTIMONIALS_GET_FAIL:
			return testimonialsGetFail(state, action);






		case actionTypes.TESTIMONIALS_POST_START:
			return testimonialsPostStart(state, action);
		case actionTypes.TESTIMONIALS_POST_SUCCESS:
			return testimonialsPostSuccess(state, action);
		case actionTypes.TESTIMONIALS_POST_FAIL:
			return testimonialsPostFail(state, action);






		case actionTypes.TESTIMONIALS_EDIT_START:
			return testimonialsEditStart(state, action);
		case actionTypes.TESTIMONIALS_EDIT_SUCCESS:
			return testimonialsEditSuccess(state, action);
		case actionTypes.TESTIMONIALS_EDIT_FAIL:
			return testimonialsEditFail(state, action);






		case actionTypes.TESTIMONIALS_DETAIL_START:
			return testimonialsDetailStart(state, action);
		case actionTypes.TESTIMONIALS_DETAIL_SUCCESS:
			return testimonialsDetailSuccess(state, action);
		case actionTypes.TESTIMONIALS_DETAIL_FAIL:
			return testimonialsDetailFail(state, action);






		case actionTypes.TESTIMONIALS_DELETE_START:
			return testimonialsDeleteStart(state, action);
		case actionTypes.TESTIMONIALS_DELETE_SUCCESS:
			return testimonialsDeleteSuccess(state, action);
		case actionTypes.TESTIMONIALS_DELETE_FAIL:
			return testimonialsDeleteFail(state, action);



		default:
			return state;
	}
};
export default reducer;
