export const GENERALSETTING_POST_START = "GENERALSETTING_POST_START"
export const GENERALSETTING_POST_SUCCESS = "GENERALSETTING_POST_SUCCESS"
export const GENERALSETTING_POST_FAIL = "GENERALSETTING_POST_FAIL"


export const GENERALSETTING_GET_START = "GENERALSETTING_GET_START"
export const GENERALSETTING_GET_SUCCESS = "GENERALSETTING_GET_SUCCESS"
export const GENERALSETTING_GET_FAIL = "GENERALSETTING_GET_FAIL"


export const GENERALSETTING_DETAIL_START = "GENERALSETTING_DETAIL_START"
export const GENERALSETTING_DETAIL_SUCCESS = "GENERALSETTING_DETAIL_SUCCESS"
export const GENERALSETTING_DETAIL_FAIL = "GENERALSETTING_DETAIL_FAIL"


export const GENERALSETTING_EDIT_START = "GENERALSETTING_EDIT_START"
export const GENERALSETTING_EDIT_SUCCESS = "GENERALSETTING_EDIT_SUCCESS"
export const GENERALSETTING_EDIT_FAIL = "GENERALSETTING_EDIT_FAIL"


export const GENERALSETTING_DELETE_START = "GENERALSETTING_DELETE_START"
export const GENERALSETTING_DELETE_SUCCESS = "GENERALSETTING_DELETE_SUCCESS"
export const GENERALSETTING_DELETE_FAIL = "GENERALSETTING_DELETE_FAIL"


