import * as actionTypes from "./actionType";
import { updateObject } from "../utility";


export const initialState = {
	error: null,
	loading: null,
	branch:[],
	branchDetail:null,
}






const branchGetStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const branchGetSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		branch: action.branch,
	});
};
const branchGetFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const branchPostStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const branchPostSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		branchDetail: action.branchDetail,
	});
};
const branchPostFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const branchEditStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const branchEditSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		branchDetail: action.branchDetail,
	});
};
const branchEditFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const branchDetailStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const branchDetailSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		branchDetail: action.branchDetail,
	});
};
const branchDetailFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const branchDeleteStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const branchDeleteSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		branchDetail: action.branchDetail,
		branch: state.branch.filter((item)=>item.id != action.branchDetail.id),
	});
};
const branchDeleteFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const reducer = (state = initialState, action) => { 
	switch (action.type) { 
		case actionTypes.BRANCH_GET_START:
			return branchGetStart(state, action);
		case actionTypes.BRANCH_GET_SUCCESS:
			return branchGetSuccess(state, action);
		case actionTypes.BRANCH_GET_FAIL:
			return branchGetFail(state, action);






		case actionTypes.BRANCH_POST_START:
			return branchPostStart(state, action);
		case actionTypes.BRANCH_POST_SUCCESS:
			return branchPostSuccess(state, action);
		case actionTypes.BRANCH_POST_FAIL:
			return branchPostFail(state, action);






		case actionTypes.BRANCH_EDIT_START:
			return branchEditStart(state, action);
		case actionTypes.BRANCH_EDIT_SUCCESS:
			return branchEditSuccess(state, action);
		case actionTypes.BRANCH_EDIT_FAIL:
			return branchEditFail(state, action);






		case actionTypes.BRANCH_DETAIL_START:
			return branchDetailStart(state, action);
		case actionTypes.BRANCH_DETAIL_SUCCESS:
			return branchDetailSuccess(state, action);
		case actionTypes.BRANCH_DETAIL_FAIL:
			return branchDetailFail(state, action);






		case actionTypes.BRANCH_DELETE_START:
			return branchDeleteStart(state, action);
		case actionTypes.BRANCH_DELETE_SUCCESS:
			return branchDeleteSuccess(state, action);
		case actionTypes.BRANCH_DELETE_FAIL:
			return branchDeleteFail(state, action);



		default:
			return state;
	}
};
export default reducer;
