import React, { Component } from 'react'

import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const PublicLayout = React.lazy(() => import('./layout/PublicLayout'))
const AdminLayout = React.lazy(() => import('./layout/AdminLayout'))



// Pages

const App = () => {
  // const isAuthenticated = useSelector((state) => state.auth.successAuth)
  const isAuthenticated = true;

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          {isAuthenticated ? (
            <Switch>
              {/* <Redirect from="/" to="/" exact /> */}
              <Redirect from="/admin" to="/admin/dashboards" exact />
              <Redirect from="/login" to="/admin/dashboards" exact />
              <Redirect from="/login" to="/admin/dashboards" exact />
              <Redirect from="/admin" to="/admin/dashboards" exact />
              <Redirect from="/register" to="/admin/dashboards" exact />
              <Route path="/admin" name="Home" render={(props) => <AdminLayout {...props} />} />
              <Route
                path="/"
                name="Public Dashboard"
                render={(props) => <PublicLayout {...props} />}
              />
              <Redirect to="/admin/404" />
            </Switch>
          ) : (
            <Switch>
              {/* <Redirect from="/" to="/" exact /> */}
              <Redirect from="/admin" to="/login" exact />
              {/* <Redirect from="/login" to="/login" exact /> */}
              <Redirect from="/admin" to="/login" exact />
              <Redirect from="/admin/dashboard" to="/login" exact />
              <Route
                path=""
                name="Public Dashboard"
                render={(props) => <PublicLayout {...props} />}
              />
              <Redirect to="/404" />
            </Switch>
          )}
        </Switch>
      </React.Suspense>
    </HashRouter>
  )
}

export default App
