import React from 'react'
import publiccontactquestionReducer from './store/publiccontactquestion/reducer'
import bookingquestionReducer from './store/bookingquestion/reducer'
import generalsettingReducer from './store/generalsetting/reducer'
import viewwisecourseReducer from './store/viewwisecourse/reducer'
import coursecategoryReducer from './store/coursecategory/reducer'
import bookingReducer from './store/booking/reducer'
import testpreprationfaqReducer from './store/testpreprationfaq/reducer'
import testpreprationReducer from './store/testprepration/reducer'
import ourpartnerReducer from './store/ourpartner/reducer'
import educationalpartnerReducer from './store/educationalpartner/reducer'
import publiccontactReducer from './store/publiccontact/reducer'
import faqReducer from './store/faq/reducer'
import branchReducer from './store/branch/reducer'
import countryReducer from './store/country/reducer'
import aboutusReducer from './store/aboutus/reducer'
import subscriberReducer from './store/subscriber/reducer'
import testimonialsReducer from './store/testimonials/reducer'
import teamReducer from './store/team/reducer'
import blogReducer from './store/blog/reducer'
import popularcourseReducer from './store/popularcourse/reducer'
import serviceReducer from './store/service/reducer'
import destinationReducer from './store/destination/reducer'

import { render } from 'react-dom'
import { createStore, compose, applyMiddleware, combineReducers } from 'redux'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import authReducer from './store/reducers/auth'
import sidebarStore from './store/reducers/store'
import App from './App'
import 'antd/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'material-icons/iconfont/material-icons.css'
import '@material/react-material-icon/dist/material-icon.css'
const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'publiccontactquestion',
    'bookingquestion',
    'generalsetting',
    'viewwisecourse',
    'coursecategory',
    'booking',
    'testpreprationfaq',
    'testprepration',
    'ourpartner',
    'educationalpartner',
    'publiccontact',
    'faq',
    'auth',
    'store',
    'destination',
    'service',
    'popularcourse',
    'blog',
    'team',
    'testimonials',
    'subscriber',
    'aboutus',
    'country',
    'branch',
  ],
}
const rootReducer = combineReducers({
  publiccontactquestion: publiccontactquestionReducer,
  bookingquestion: bookingquestionReducer,
  generalsetting: generalsettingReducer,
  viewwisecourse: viewwisecourseReducer,
  coursecategory: coursecategoryReducer,
  booking: bookingReducer,
  testpreprationfaq: testpreprationfaqReducer,
  testprepration: testpreprationReducer,
  ourpartner: ourpartnerReducer,
  educationalpartner: educationalpartnerReducer,
  publiccontact: publiccontactReducer,
  faq: faqReducer,
  branch: branchReducer,
  country: countryReducer,
  aboutus: aboutusReducer,
  subscriber: subscriberReducer,
  testimonials: testimonialsReducer,
  team: teamReducer,
  blog: blogReducer,
  popularcourse: popularcourseReducer,
  service: serviceReducer,
  destination: destinationReducer,
  auth: authReducer,
  store: sidebarStore,
})

const store = createStore(
  persistReducer(persistConfig, rootReducer),
  composeEnhances(applyMiddleware(thunk)),
)
const persistor = persistStore(store)

const app = (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
)

render(app, document.getElementById('root'))
