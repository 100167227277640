import * as actionTypes from './actionType'
import { updateObject } from '../utility'

export const initialState = {
  error: null,
  loading: null,
  destination: [],
  destinationDetail: null,
}

const destinationGetStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const destinationGetSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    destination: action.destination,
  })
}
const destinationGetFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const destinationPostStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const destinationPostSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    destinationDetail: action.destinationDetail,
  })
}
const destinationPostFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const destinationEditStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const destinationEditSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    destinationDetail: action.destinationDetail,
  })
}
const destinationEditFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const destinationDetailStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const destinationDetailSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    destinationDetail: action.destinationDetail,
  })
}
const destinationDetailFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const destinationDeleteStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const destinationDeleteSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    destinationDetail: action.destinationDetail,
    destination: state.destination.filter((item) => item.id != action.destinationDetail.id),
  })
}
const destinationDeleteFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DESTINATION_GET_START:
      return destinationGetStart(state, action)
    case actionTypes.DESTINATION_GET_SUCCESS:
      return destinationGetSuccess(state, action)
    case actionTypes.DESTINATION_GET_FAIL:
      return destinationGetFail(state, action)

    case actionTypes.DESTINATION_POST_START:
      return destinationPostStart(state, action)
    case actionTypes.DESTINATION_POST_SUCCESS:
      return destinationPostSuccess(state, action)
    case actionTypes.DESTINATION_POST_FAIL:
      return destinationPostFail(state, action)

    case actionTypes.DESTINATION_EDIT_START:
      return destinationEditStart(state, action)
    case actionTypes.DESTINATION_EDIT_SUCCESS:
      return destinationEditSuccess(state, action)
    case actionTypes.DESTINATION_EDIT_FAIL:
      return destinationEditFail(state, action)

    case actionTypes.DESTINATION_DETAIL_START:
      return destinationDetailStart(state, action)
    case actionTypes.DESTINATION_DETAIL_SUCCESS:
      return destinationDetailSuccess(state, action)
    case actionTypes.DESTINATION_DETAIL_FAIL:
      return destinationDetailFail(state, action)

    case actionTypes.DESTINATION_DELETE_START:
      return destinationDeleteStart(state, action)
    case actionTypes.DESTINATION_DELETE_SUCCESS:
      return destinationDeleteSuccess(state, action)
    case actionTypes.DESTINATION_DELETE_FAIL:
      return destinationDeleteFail(state, action)

    default:
      return state
  }
}
export default reducer
