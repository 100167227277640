import * as actionTypes from "./actionType";
import { updateObject } from "../utility";


export const initialState = {
	error: null,
	loading: null,
	viewwisecourse:[],
	viewwisecourseDetail:null,
}






const viewwisecourseGetStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const viewwisecourseGetSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		viewwisecourse: action.viewwisecourse,
	});
};
const viewwisecourseGetFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const viewwisecoursePostStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const viewwisecoursePostSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		viewwisecourseDetail: action.viewwisecourseDetail,
	});
};
const viewwisecoursePostFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const viewwisecourseEditStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const viewwisecourseEditSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		viewwisecourseDetail: action.viewwisecourseDetail,
	});
};
const viewwisecourseEditFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const viewwisecourseDetailStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const viewwisecourseDetailSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		viewwisecourseDetail: action.viewwisecourseDetail,
	});
};
const viewwisecourseDetailFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const viewwisecourseDeleteStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const viewwisecourseDeleteSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		viewwisecourseDetail: action.viewwisecourseDetail,
		viewwisecourse: state.viewwisecourse.filter((item)=>item.id != action.viewwisecourseDetail.id),
	});
};
const viewwisecourseDeleteFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const reducer = (state = initialState, action) => { 
	switch (action.type) { 
		case actionTypes.VIEWWISECOURSE_GET_START:
			return viewwisecourseGetStart(state, action);
		case actionTypes.VIEWWISECOURSE_GET_SUCCESS:
			return viewwisecourseGetSuccess(state, action);
		case actionTypes.VIEWWISECOURSE_GET_FAIL:
			return viewwisecourseGetFail(state, action);






		case actionTypes.VIEWWISECOURSE_POST_START:
			return viewwisecoursePostStart(state, action);
		case actionTypes.VIEWWISECOURSE_POST_SUCCESS:
			return viewwisecoursePostSuccess(state, action);
		case actionTypes.VIEWWISECOURSE_POST_FAIL:
			return viewwisecoursePostFail(state, action);






		case actionTypes.VIEWWISECOURSE_EDIT_START:
			return viewwisecourseEditStart(state, action);
		case actionTypes.VIEWWISECOURSE_EDIT_SUCCESS:
			return viewwisecourseEditSuccess(state, action);
		case actionTypes.VIEWWISECOURSE_EDIT_FAIL:
			return viewwisecourseEditFail(state, action);






		case actionTypes.VIEWWISECOURSE_DETAIL_START:
			return viewwisecourseDetailStart(state, action);
		case actionTypes.VIEWWISECOURSE_DETAIL_SUCCESS:
			return viewwisecourseDetailSuccess(state, action);
		case actionTypes.VIEWWISECOURSE_DETAIL_FAIL:
			return viewwisecourseDetailFail(state, action);






		case actionTypes.VIEWWISECOURSE_DELETE_START:
			return viewwisecourseDeleteStart(state, action);
		case actionTypes.VIEWWISECOURSE_DELETE_SUCCESS:
			return viewwisecourseDeleteSuccess(state, action);
		case actionTypes.VIEWWISECOURSE_DELETE_FAIL:
			return viewwisecourseDeleteFail(state, action);



		default:
			return state;
	}
};
export default reducer;
