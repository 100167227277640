import * as actionTypes from "./actionType";
import { updateObject } from "../utility";


export const initialState = {
	error: null,
	loading: null,
	ourpartner:[],
	ourpartnerDetail:null,
}






const ourpartnerGetStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const ourpartnerGetSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		ourpartner: action.ourpartner,
	});
};
const ourpartnerGetFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const ourpartnerPostStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const ourpartnerPostSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		ourpartnerDetail: action.ourpartnerDetail,
	});
};
const ourpartnerPostFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const ourpartnerEditStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const ourpartnerEditSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		ourpartnerDetail: action.ourpartnerDetail,
	});
};
const ourpartnerEditFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const ourpartnerDetailStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const ourpartnerDetailSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		ourpartnerDetail: action.ourpartnerDetail,
	});
};
const ourpartnerDetailFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const ourpartnerDeleteStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const ourpartnerDeleteSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		ourpartnerDetail: action.ourpartnerDetail,
		ourpartner: state.ourpartner.filter((item)=>item.id != action.ourpartnerDetail.id),
	});
};
const ourpartnerDeleteFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const reducer = (state = initialState, action) => { 
	switch (action.type) { 
		case actionTypes.OURPARTNER_GET_START:
			return ourpartnerGetStart(state, action);
		case actionTypes.OURPARTNER_GET_SUCCESS:
			return ourpartnerGetSuccess(state, action);
		case actionTypes.OURPARTNER_GET_FAIL:
			return ourpartnerGetFail(state, action);






		case actionTypes.OURPARTNER_POST_START:
			return ourpartnerPostStart(state, action);
		case actionTypes.OURPARTNER_POST_SUCCESS:
			return ourpartnerPostSuccess(state, action);
		case actionTypes.OURPARTNER_POST_FAIL:
			return ourpartnerPostFail(state, action);






		case actionTypes.OURPARTNER_EDIT_START:
			return ourpartnerEditStart(state, action);
		case actionTypes.OURPARTNER_EDIT_SUCCESS:
			return ourpartnerEditSuccess(state, action);
		case actionTypes.OURPARTNER_EDIT_FAIL:
			return ourpartnerEditFail(state, action);






		case actionTypes.OURPARTNER_DETAIL_START:
			return ourpartnerDetailStart(state, action);
		case actionTypes.OURPARTNER_DETAIL_SUCCESS:
			return ourpartnerDetailSuccess(state, action);
		case actionTypes.OURPARTNER_DETAIL_FAIL:
			return ourpartnerDetailFail(state, action);






		case actionTypes.OURPARTNER_DELETE_START:
			return ourpartnerDeleteStart(state, action);
		case actionTypes.OURPARTNER_DELETE_SUCCESS:
			return ourpartnerDeleteSuccess(state, action);
		case actionTypes.OURPARTNER_DELETE_FAIL:
			return ourpartnerDeleteFail(state, action);



		default:
			return state;
	}
};
export default reducer;
