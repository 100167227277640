export const COURSECATEGORY_POST_START = "COURSECATEGORY_POST_START"
export const COURSECATEGORY_POST_SUCCESS = "COURSECATEGORY_POST_SUCCESS"
export const COURSECATEGORY_POST_FAIL = "COURSECATEGORY_POST_FAIL"


export const COURSECATEGORY_GET_START = "COURSECATEGORY_GET_START"
export const COURSECATEGORY_GET_SUCCESS = "COURSECATEGORY_GET_SUCCESS"
export const COURSECATEGORY_GET_FAIL = "COURSECATEGORY_GET_FAIL"


export const COURSECATEGORY_DETAIL_START = "COURSECATEGORY_DETAIL_START"
export const COURSECATEGORY_DETAIL_SUCCESS = "COURSECATEGORY_DETAIL_SUCCESS"
export const COURSECATEGORY_DETAIL_FAIL = "COURSECATEGORY_DETAIL_FAIL"


export const COURSECATEGORY_EDIT_START = "COURSECATEGORY_EDIT_START"
export const COURSECATEGORY_EDIT_SUCCESS = "COURSECATEGORY_EDIT_SUCCESS"
export const COURSECATEGORY_EDIT_FAIL = "COURSECATEGORY_EDIT_FAIL"


export const COURSECATEGORY_DELETE_START = "COURSECATEGORY_DELETE_START"
export const COURSECATEGORY_DELETE_SUCCESS = "COURSECATEGORY_DELETE_SUCCESS"
export const COURSECATEGORY_DELETE_FAIL = "COURSECATEGORY_DELETE_FAIL"


