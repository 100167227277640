export const POPULARCOURSE_POST_START = "POPULARCOURSE_POST_START"
export const POPULARCOURSE_POST_SUCCESS = "POPULARCOURSE_POST_SUCCESS"
export const POPULARCOURSE_POST_FAIL = "POPULARCOURSE_POST_FAIL"


export const POPULARCOURSE_GET_START = "POPULARCOURSE_GET_START"
export const POPULARCOURSE_GET_SUCCESS = "POPULARCOURSE_GET_SUCCESS"
export const POPULARCOURSE_GET_FAIL = "POPULARCOURSE_GET_FAIL"


export const POPULARCOURSE_DETAIL_START = "POPULARCOURSE_DETAIL_START"
export const POPULARCOURSE_DETAIL_SUCCESS = "POPULARCOURSE_DETAIL_SUCCESS"
export const POPULARCOURSE_DETAIL_FAIL = "POPULARCOURSE_DETAIL_FAIL"


export const POPULARCOURSE_EDIT_START = "POPULARCOURSE_EDIT_START"
export const POPULARCOURSE_EDIT_SUCCESS = "POPULARCOURSE_EDIT_SUCCESS"
export const POPULARCOURSE_EDIT_FAIL = "POPULARCOURSE_EDIT_FAIL"


export const POPULARCOURSE_DELETE_START = "POPULARCOURSE_DELETE_START"
export const POPULARCOURSE_DELETE_SUCCESS = "POPULARCOURSE_DELETE_SUCCESS"
export const POPULARCOURSE_DELETE_FAIL = "POPULARCOURSE_DELETE_FAIL"


