import * as actionTypes from "./actionType";
import { updateObject } from "../utility";


export const initialState = {
	error: null,
	loading: null,
	generalsetting:[],
	generalsettingDetail:null,
}






const generalsettingGetStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const generalsettingGetSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		generalsetting: action.generalsetting,
	});
};
const generalsettingGetFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const generalsettingPostStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const generalsettingPostSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		generalsettingDetail: action.generalsettingDetail,
	});
};
const generalsettingPostFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const generalsettingEditStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const generalsettingEditSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		generalsettingDetail: action.generalsettingDetail,
	});
};
const generalsettingEditFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const generalsettingDetailStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const generalsettingDetailSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		generalsettingDetail: action.generalsettingDetail,
	});
};
const generalsettingDetailFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const generalsettingDeleteStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const generalsettingDeleteSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		generalsettingDetail: action.generalsettingDetail,
		generalsetting: state.generalsetting.filter((item)=>item.id != action.generalsettingDetail.id),
	});
};
const generalsettingDeleteFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const reducer = (state = initialState, action) => { 
	switch (action.type) { 
		case actionTypes.GENERALSETTING_GET_START:
			return generalsettingGetStart(state, action);
		case actionTypes.GENERALSETTING_GET_SUCCESS:
			return generalsettingGetSuccess(state, action);
		case actionTypes.GENERALSETTING_GET_FAIL:
			return generalsettingGetFail(state, action);






		case actionTypes.GENERALSETTING_POST_START:
			return generalsettingPostStart(state, action);
		case actionTypes.GENERALSETTING_POST_SUCCESS:
			return generalsettingPostSuccess(state, action);
		case actionTypes.GENERALSETTING_POST_FAIL:
			return generalsettingPostFail(state, action);






		case actionTypes.GENERALSETTING_EDIT_START:
			return generalsettingEditStart(state, action);
		case actionTypes.GENERALSETTING_EDIT_SUCCESS:
			return generalsettingEditSuccess(state, action);
		case actionTypes.GENERALSETTING_EDIT_FAIL:
			return generalsettingEditFail(state, action);






		case actionTypes.GENERALSETTING_DETAIL_START:
			return generalsettingDetailStart(state, action);
		case actionTypes.GENERALSETTING_DETAIL_SUCCESS:
			return generalsettingDetailSuccess(state, action);
		case actionTypes.GENERALSETTING_DETAIL_FAIL:
			return generalsettingDetailFail(state, action);






		case actionTypes.GENERALSETTING_DELETE_START:
			return generalsettingDeleteStart(state, action);
		case actionTypes.GENERALSETTING_DELETE_SUCCESS:
			return generalsettingDeleteSuccess(state, action);
		case actionTypes.GENERALSETTING_DELETE_FAIL:
			return generalsettingDeleteFail(state, action);



		default:
			return state;
	}
};
export default reducer;
