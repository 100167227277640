export const BLOG_POST_START = "BLOG_POST_START"
export const BLOG_POST_SUCCESS = "BLOG_POST_SUCCESS"
export const BLOG_POST_FAIL = "BLOG_POST_FAIL"


export const BLOG_GET_START = "BLOG_GET_START"
export const BLOG_GET_SUCCESS = "BLOG_GET_SUCCESS"
export const BLOG_GET_FAIL = "BLOG_GET_FAIL"


export const BLOG_DETAIL_START = "BLOG_DETAIL_START"
export const BLOG_DETAIL_SUCCESS = "BLOG_DETAIL_SUCCESS"
export const BLOG_DETAIL_FAIL = "BLOG_DETAIL_FAIL"


export const BLOG_EDIT_START = "BLOG_EDIT_START"
export const BLOG_EDIT_SUCCESS = "BLOG_EDIT_SUCCESS"
export const BLOG_EDIT_FAIL = "BLOG_EDIT_FAIL"


export const BLOG_DELETE_START = "BLOG_DELETE_START"
export const BLOG_DELETE_SUCCESS = "BLOG_DELETE_SUCCESS"
export const BLOG_DELETE_FAIL = "BLOG_DELETE_FAIL"


