import * as actionTypes from './actionType'
import { updateObject } from '../utility'

export const initialState = {
  error: null,
  loading: null,
  blog: [],
  blogDetail: null,
}

const blogGetStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const blogGetSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    blog: action.blog,
  })
}
const blogGetFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const blogPostStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const blogPostSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    blogDetail: action.blogDetail,
  })
}
const blogPostFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const blogEditStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const blogEditSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    blogDetail: action.blogDetail,
  })
}
const blogEditFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const blogDetailStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const blogDetailSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    blogDetail: action.blogDetail,
  })
}
const blogDetailFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const blogDeleteStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const blogDeleteSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    blogDetail: action.blogDetail,
    blog: state.blog.filter((item) => item.id != action.blogDetail.id),
  })
}
const blogDeleteFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BLOG_GET_START:
      return blogGetStart(state, action)
    case actionTypes.BLOG_GET_SUCCESS:
      return blogGetSuccess(state, action)
    case actionTypes.BLOG_GET_FAIL:
      return blogGetFail(state, action)

    case actionTypes.BLOG_POST_START:
      return blogPostStart(state, action)
    case actionTypes.BLOG_POST_SUCCESS:
      return blogPostSuccess(state, action)
    case actionTypes.BLOG_POST_FAIL:
      return blogPostFail(state, action)

    case actionTypes.BLOG_EDIT_START:
      return blogEditStart(state, action)
    case actionTypes.BLOG_EDIT_SUCCESS:
      return blogEditSuccess(state, action)
    case actionTypes.BLOG_EDIT_FAIL:
      return blogEditFail(state, action)

    case actionTypes.BLOG_DETAIL_START:
      return blogDetailStart(state, action)
    case actionTypes.BLOG_DETAIL_SUCCESS:
      return blogDetailSuccess(state, action)
    case actionTypes.BLOG_DETAIL_FAIL:
      return blogDetailFail(state, action)

    case actionTypes.BLOG_DELETE_START:
      return blogDeleteStart(state, action)
    case actionTypes.BLOG_DELETE_SUCCESS:
      return blogDeleteSuccess(state, action)
    case actionTypes.BLOG_DELETE_FAIL:
      return blogDeleteFail(state, action)

    default:
      return state
  }
}
export default reducer
