export const BOOKING_POST_START = "BOOKING_POST_START"
export const BOOKING_POST_SUCCESS = "BOOKING_POST_SUCCESS"
export const BOOKING_POST_FAIL = "BOOKING_POST_FAIL"


export const BOOKING_GET_START = "BOOKING_GET_START"
export const BOOKING_GET_SUCCESS = "BOOKING_GET_SUCCESS"
export const BOOKING_GET_FAIL = "BOOKING_GET_FAIL"


export const BOOKING_DETAIL_START = "BOOKING_DETAIL_START"
export const BOOKING_DETAIL_SUCCESS = "BOOKING_DETAIL_SUCCESS"
export const BOOKING_DETAIL_FAIL = "BOOKING_DETAIL_FAIL"


export const BOOKING_EDIT_START = "BOOKING_EDIT_START"
export const BOOKING_EDIT_SUCCESS = "BOOKING_EDIT_SUCCESS"
export const BOOKING_EDIT_FAIL = "BOOKING_EDIT_FAIL"


export const BOOKING_DELETE_START = "BOOKING_DELETE_START"
export const BOOKING_DELETE_SUCCESS = "BOOKING_DELETE_SUCCESS"
export const BOOKING_DELETE_FAIL = "BOOKING_DELETE_FAIL"


