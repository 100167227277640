import * as actionTypes from "./actionType";
import { updateObject } from "../utility";


export const initialState = {
	error: null,
	loading: null,
	publiccontact:[],
	publiccontactDetail:null,
}






const publiccontactGetStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const publiccontactGetSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		publiccontact: action.publiccontact,
	});
};
const publiccontactGetFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const publiccontactPostStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const publiccontactPostSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		publiccontactDetail: action.publiccontactDetail,
	});
};
const publiccontactPostFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const publiccontactEditStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const publiccontactEditSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		publiccontactDetail: action.publiccontactDetail,
	});
};
const publiccontactEditFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const publiccontactDetailStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const publiccontactDetailSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		publiccontactDetail: action.publiccontactDetail,
	});
};
const publiccontactDetailFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const publiccontactDeleteStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const publiccontactDeleteSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		publiccontactDetail: action.publiccontactDetail,
		publiccontact: state.publiccontact.filter((item)=>item.id != action.publiccontactDetail.id),
	});
};
const publiccontactDeleteFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const reducer = (state = initialState, action) => { 
	switch (action.type) { 
		case actionTypes.PUBLICCONTACT_GET_START:
			return publiccontactGetStart(state, action);
		case actionTypes.PUBLICCONTACT_GET_SUCCESS:
			return publiccontactGetSuccess(state, action);
		case actionTypes.PUBLICCONTACT_GET_FAIL:
			return publiccontactGetFail(state, action);






		case actionTypes.PUBLICCONTACT_POST_START:
			return publiccontactPostStart(state, action);
		case actionTypes.PUBLICCONTACT_POST_SUCCESS:
			return publiccontactPostSuccess(state, action);
		case actionTypes.PUBLICCONTACT_POST_FAIL:
			return publiccontactPostFail(state, action);






		case actionTypes.PUBLICCONTACT_EDIT_START:
			return publiccontactEditStart(state, action);
		case actionTypes.PUBLICCONTACT_EDIT_SUCCESS:
			return publiccontactEditSuccess(state, action);
		case actionTypes.PUBLICCONTACT_EDIT_FAIL:
			return publiccontactEditFail(state, action);






		case actionTypes.PUBLICCONTACT_DETAIL_START:
			return publiccontactDetailStart(state, action);
		case actionTypes.PUBLICCONTACT_DETAIL_SUCCESS:
			return publiccontactDetailSuccess(state, action);
		case actionTypes.PUBLICCONTACT_DETAIL_FAIL:
			return publiccontactDetailFail(state, action);






		case actionTypes.PUBLICCONTACT_DELETE_START:
			return publiccontactDeleteStart(state, action);
		case actionTypes.PUBLICCONTACT_DELETE_SUCCESS:
			return publiccontactDeleteSuccess(state, action);
		case actionTypes.PUBLICCONTACT_DELETE_FAIL:
			return publiccontactDeleteFail(state, action);



		default:
			return state;
	}
};
export default reducer;
