export const OURPARTNER_POST_START = "OURPARTNER_POST_START"
export const OURPARTNER_POST_SUCCESS = "OURPARTNER_POST_SUCCESS"
export const OURPARTNER_POST_FAIL = "OURPARTNER_POST_FAIL"


export const OURPARTNER_GET_START = "OURPARTNER_GET_START"
export const OURPARTNER_GET_SUCCESS = "OURPARTNER_GET_SUCCESS"
export const OURPARTNER_GET_FAIL = "OURPARTNER_GET_FAIL"


export const OURPARTNER_DETAIL_START = "OURPARTNER_DETAIL_START"
export const OURPARTNER_DETAIL_SUCCESS = "OURPARTNER_DETAIL_SUCCESS"
export const OURPARTNER_DETAIL_FAIL = "OURPARTNER_DETAIL_FAIL"


export const OURPARTNER_EDIT_START = "OURPARTNER_EDIT_START"
export const OURPARTNER_EDIT_SUCCESS = "OURPARTNER_EDIT_SUCCESS"
export const OURPARTNER_EDIT_FAIL = "OURPARTNER_EDIT_FAIL"


export const OURPARTNER_DELETE_START = "OURPARTNER_DELETE_START"
export const OURPARTNER_DELETE_SUCCESS = "OURPARTNER_DELETE_SUCCESS"
export const OURPARTNER_DELETE_FAIL = "OURPARTNER_DELETE_FAIL"


