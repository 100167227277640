export const TESTPREPRATIONFAQ_POST_START = "TESTPREPRATIONFAQ_POST_START"
export const TESTPREPRATIONFAQ_POST_SUCCESS = "TESTPREPRATIONFAQ_POST_SUCCESS"
export const TESTPREPRATIONFAQ_POST_FAIL = "TESTPREPRATIONFAQ_POST_FAIL"


export const TESTPREPRATIONFAQ_GET_START = "TESTPREPRATIONFAQ_GET_START"
export const TESTPREPRATIONFAQ_GET_SUCCESS = "TESTPREPRATIONFAQ_GET_SUCCESS"
export const TESTPREPRATIONFAQ_GET_FAIL = "TESTPREPRATIONFAQ_GET_FAIL"


export const TESTPREPRATIONFAQ_DETAIL_START = "TESTPREPRATIONFAQ_DETAIL_START"
export const TESTPREPRATIONFAQ_DETAIL_SUCCESS = "TESTPREPRATIONFAQ_DETAIL_SUCCESS"
export const TESTPREPRATIONFAQ_DETAIL_FAIL = "TESTPREPRATIONFAQ_DETAIL_FAIL"


export const TESTPREPRATIONFAQ_EDIT_START = "TESTPREPRATIONFAQ_EDIT_START"
export const TESTPREPRATIONFAQ_EDIT_SUCCESS = "TESTPREPRATIONFAQ_EDIT_SUCCESS"
export const TESTPREPRATIONFAQ_EDIT_FAIL = "TESTPREPRATIONFAQ_EDIT_FAIL"


export const TESTPREPRATIONFAQ_DELETE_START = "TESTPREPRATIONFAQ_DELETE_START"
export const TESTPREPRATIONFAQ_DELETE_SUCCESS = "TESTPREPRATIONFAQ_DELETE_SUCCESS"
export const TESTPREPRATIONFAQ_DELETE_FAIL = "TESTPREPRATIONFAQ_DELETE_FAIL"


