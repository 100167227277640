export const BOOKINGQUESTION_POST_START = "BOOKINGQUESTION_POST_START"
export const BOOKINGQUESTION_POST_SUCCESS = "BOOKINGQUESTION_POST_SUCCESS"
export const BOOKINGQUESTION_POST_FAIL = "BOOKINGQUESTION_POST_FAIL"


export const BOOKINGQUESTION_GET_START = "BOOKINGQUESTION_GET_START"
export const BOOKINGQUESTION_GET_SUCCESS = "BOOKINGQUESTION_GET_SUCCESS"
export const BOOKINGQUESTION_GET_FAIL = "BOOKINGQUESTION_GET_FAIL"


export const BOOKINGQUESTION_DETAIL_START = "BOOKINGQUESTION_DETAIL_START"
export const BOOKINGQUESTION_DETAIL_SUCCESS = "BOOKINGQUESTION_DETAIL_SUCCESS"
export const BOOKINGQUESTION_DETAIL_FAIL = "BOOKINGQUESTION_DETAIL_FAIL"


export const BOOKINGQUESTION_EDIT_START = "BOOKINGQUESTION_EDIT_START"
export const BOOKINGQUESTION_EDIT_SUCCESS = "BOOKINGQUESTION_EDIT_SUCCESS"
export const BOOKINGQUESTION_EDIT_FAIL = "BOOKINGQUESTION_EDIT_FAIL"


export const BOOKINGQUESTION_DELETE_START = "BOOKINGQUESTION_DELETE_START"
export const BOOKINGQUESTION_DELETE_SUCCESS = "BOOKINGQUESTION_DELETE_SUCCESS"
export const BOOKINGQUESTION_DELETE_FAIL = "BOOKINGQUESTION_DELETE_FAIL"


