import * as actionTypes from "./actionType";
import { updateObject } from "../utility";


export const initialState = {
	error: null,
	loading: null,
	team:[],
	teamDetail:null,
}






const teamGetStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const teamGetSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		team: action.team,
	});
};
const teamGetFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const teamPostStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const teamPostSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		teamDetail: action.teamDetail,
	});
};
const teamPostFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const teamEditStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const teamEditSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		teamDetail: action.teamDetail,
	});
};
const teamEditFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const teamDetailStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const teamDetailSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		teamDetail: action.teamDetail,
	});
};
const teamDetailFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const teamDeleteStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const teamDeleteSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		teamDetail: action.teamDetail,
		team: state.team.filter((item)=>item.id != action.teamDetail.id),
	});
};
const teamDeleteFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const reducer = (state = initialState, action) => { 
	switch (action.type) { 
		case actionTypes.TEAM_GET_START:
			return teamGetStart(state, action);
		case actionTypes.TEAM_GET_SUCCESS:
			return teamGetSuccess(state, action);
		case actionTypes.TEAM_GET_FAIL:
			return teamGetFail(state, action);






		case actionTypes.TEAM_POST_START:
			return teamPostStart(state, action);
		case actionTypes.TEAM_POST_SUCCESS:
			return teamPostSuccess(state, action);
		case actionTypes.TEAM_POST_FAIL:
			return teamPostFail(state, action);






		case actionTypes.TEAM_EDIT_START:
			return teamEditStart(state, action);
		case actionTypes.TEAM_EDIT_SUCCESS:
			return teamEditSuccess(state, action);
		case actionTypes.TEAM_EDIT_FAIL:
			return teamEditFail(state, action);






		case actionTypes.TEAM_DETAIL_START:
			return teamDetailStart(state, action);
		case actionTypes.TEAM_DETAIL_SUCCESS:
			return teamDetailSuccess(state, action);
		case actionTypes.TEAM_DETAIL_FAIL:
			return teamDetailFail(state, action);






		case actionTypes.TEAM_DELETE_START:
			return teamDeleteStart(state, action);
		case actionTypes.TEAM_DELETE_SUCCESS:
			return teamDeleteSuccess(state, action);
		case actionTypes.TEAM_DELETE_FAIL:
			return teamDeleteFail(state, action);



		default:
			return state;
	}
};
export default reducer;
