export const TESTPREPRATION_POST_START = "TESTPREPRATION_POST_START"
export const TESTPREPRATION_POST_SUCCESS = "TESTPREPRATION_POST_SUCCESS"
export const TESTPREPRATION_POST_FAIL = "TESTPREPRATION_POST_FAIL"


export const TESTPREPRATION_GET_START = "TESTPREPRATION_GET_START"
export const TESTPREPRATION_GET_SUCCESS = "TESTPREPRATION_GET_SUCCESS"
export const TESTPREPRATION_GET_FAIL = "TESTPREPRATION_GET_FAIL"


export const TESTPREPRATION_DETAIL_START = "TESTPREPRATION_DETAIL_START"
export const TESTPREPRATION_DETAIL_SUCCESS = "TESTPREPRATION_DETAIL_SUCCESS"
export const TESTPREPRATION_DETAIL_FAIL = "TESTPREPRATION_DETAIL_FAIL"


export const TESTPREPRATION_EDIT_START = "TESTPREPRATION_EDIT_START"
export const TESTPREPRATION_EDIT_SUCCESS = "TESTPREPRATION_EDIT_SUCCESS"
export const TESTPREPRATION_EDIT_FAIL = "TESTPREPRATION_EDIT_FAIL"


export const TESTPREPRATION_DELETE_START = "TESTPREPRATION_DELETE_START"
export const TESTPREPRATION_DELETE_SUCCESS = "TESTPREPRATION_DELETE_SUCCESS"
export const TESTPREPRATION_DELETE_FAIL = "TESTPREPRATION_DELETE_FAIL"


