import * as actionTypes from "./actionType";
import { updateObject } from "../utility";


export const initialState = {
	error: null,
	loading: null,
	country:[],
	countryDetail:null,
}






const countryGetStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const countryGetSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		country: action.country,
	});
};
const countryGetFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const countryPostStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const countryPostSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		countryDetail: action.countryDetail,
	});
};
const countryPostFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const countryEditStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const countryEditSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		countryDetail: action.countryDetail,
	});
};
const countryEditFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const countryDetailStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const countryDetailSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		countryDetail: action.countryDetail,
	});
};
const countryDetailFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const countryDeleteStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const countryDeleteSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		countryDetail: action.countryDetail,
		country: state.country.filter((item)=>item.id != action.countryDetail.id),
	});
};
const countryDeleteFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const reducer = (state = initialState, action) => { 
	switch (action.type) { 
		case actionTypes.COUNTRY_GET_START:
			return countryGetStart(state, action);
		case actionTypes.COUNTRY_GET_SUCCESS:
			return countryGetSuccess(state, action);
		case actionTypes.COUNTRY_GET_FAIL:
			return countryGetFail(state, action);






		case actionTypes.COUNTRY_POST_START:
			return countryPostStart(state, action);
		case actionTypes.COUNTRY_POST_SUCCESS:
			return countryPostSuccess(state, action);
		case actionTypes.COUNTRY_POST_FAIL:
			return countryPostFail(state, action);






		case actionTypes.COUNTRY_EDIT_START:
			return countryEditStart(state, action);
		case actionTypes.COUNTRY_EDIT_SUCCESS:
			return countryEditSuccess(state, action);
		case actionTypes.COUNTRY_EDIT_FAIL:
			return countryEditFail(state, action);






		case actionTypes.COUNTRY_DETAIL_START:
			return countryDetailStart(state, action);
		case actionTypes.COUNTRY_DETAIL_SUCCESS:
			return countryDetailSuccess(state, action);
		case actionTypes.COUNTRY_DETAIL_FAIL:
			return countryDetailFail(state, action);






		case actionTypes.COUNTRY_DELETE_START:
			return countryDeleteStart(state, action);
		case actionTypes.COUNTRY_DELETE_SUCCESS:
			return countryDeleteSuccess(state, action);
		case actionTypes.COUNTRY_DELETE_FAIL:
			return countryDeleteFail(state, action);



		default:
			return state;
	}
};
export default reducer;
