export const PUBLICCONTACT_POST_START = "PUBLICCONTACT_POST_START"
export const PUBLICCONTACT_POST_SUCCESS = "PUBLICCONTACT_POST_SUCCESS"
export const PUBLICCONTACT_POST_FAIL = "PUBLICCONTACT_POST_FAIL"


export const PUBLICCONTACT_GET_START = "PUBLICCONTACT_GET_START"
export const PUBLICCONTACT_GET_SUCCESS = "PUBLICCONTACT_GET_SUCCESS"
export const PUBLICCONTACT_GET_FAIL = "PUBLICCONTACT_GET_FAIL"


export const PUBLICCONTACT_DETAIL_START = "PUBLICCONTACT_DETAIL_START"
export const PUBLICCONTACT_DETAIL_SUCCESS = "PUBLICCONTACT_DETAIL_SUCCESS"
export const PUBLICCONTACT_DETAIL_FAIL = "PUBLICCONTACT_DETAIL_FAIL"


export const PUBLICCONTACT_EDIT_START = "PUBLICCONTACT_EDIT_START"
export const PUBLICCONTACT_EDIT_SUCCESS = "PUBLICCONTACT_EDIT_SUCCESS"
export const PUBLICCONTACT_EDIT_FAIL = "PUBLICCONTACT_EDIT_FAIL"


export const PUBLICCONTACT_DELETE_START = "PUBLICCONTACT_DELETE_START"
export const PUBLICCONTACT_DELETE_SUCCESS = "PUBLICCONTACT_DELETE_SUCCESS"
export const PUBLICCONTACT_DELETE_FAIL = "PUBLICCONTACT_DELETE_FAIL"


