import * as actionTypes from "./actionType";
import { updateObject } from "../utility";


export const initialState = {
	error: null,
	loading: null,
	testprepration:[],
	testpreprationDetail:null,
}






const testpreprationGetStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const testpreprationGetSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		testprepration: action.testprepration,
	});
};
const testpreprationGetFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const testpreprationPostStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const testpreprationPostSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		testpreprationDetail: action.testpreprationDetail,
	});
};
const testpreprationPostFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const testpreprationEditStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const testpreprationEditSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		testpreprationDetail: action.testpreprationDetail,
	});
};
const testpreprationEditFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const testpreprationDetailStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const testpreprationDetailSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		testpreprationDetail: action.testpreprationDetail,
	});
};
const testpreprationDetailFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const testpreprationDeleteStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const testpreprationDeleteSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		testpreprationDetail: action.testpreprationDetail,
		testprepration: state.testprepration.filter((item)=>item.id != action.testpreprationDetail.id),
	});
};
const testpreprationDeleteFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const reducer = (state = initialState, action) => { 
	switch (action.type) { 
		case actionTypes.TESTPREPRATION_GET_START:
			return testpreprationGetStart(state, action);
		case actionTypes.TESTPREPRATION_GET_SUCCESS:
			return testpreprationGetSuccess(state, action);
		case actionTypes.TESTPREPRATION_GET_FAIL:
			return testpreprationGetFail(state, action);






		case actionTypes.TESTPREPRATION_POST_START:
			return testpreprationPostStart(state, action);
		case actionTypes.TESTPREPRATION_POST_SUCCESS:
			return testpreprationPostSuccess(state, action);
		case actionTypes.TESTPREPRATION_POST_FAIL:
			return testpreprationPostFail(state, action);






		case actionTypes.TESTPREPRATION_EDIT_START:
			return testpreprationEditStart(state, action);
		case actionTypes.TESTPREPRATION_EDIT_SUCCESS:
			return testpreprationEditSuccess(state, action);
		case actionTypes.TESTPREPRATION_EDIT_FAIL:
			return testpreprationEditFail(state, action);






		case actionTypes.TESTPREPRATION_DETAIL_START:
			return testpreprationDetailStart(state, action);
		case actionTypes.TESTPREPRATION_DETAIL_SUCCESS:
			return testpreprationDetailSuccess(state, action);
		case actionTypes.TESTPREPRATION_DETAIL_FAIL:
			return testpreprationDetailFail(state, action);






		case actionTypes.TESTPREPRATION_DELETE_START:
			return testpreprationDeleteStart(state, action);
		case actionTypes.TESTPREPRATION_DELETE_SUCCESS:
			return testpreprationDeleteSuccess(state, action);
		case actionTypes.TESTPREPRATION_DELETE_FAIL:
			return testpreprationDeleteFail(state, action);



		default:
			return state;
	}
};
export default reducer;
