export const FAQ_POST_START = "FAQ_POST_START"
export const FAQ_POST_SUCCESS = "FAQ_POST_SUCCESS"
export const FAQ_POST_FAIL = "FAQ_POST_FAIL"


export const FAQ_GET_START = "FAQ_GET_START"
export const FAQ_GET_SUCCESS = "FAQ_GET_SUCCESS"
export const FAQ_GET_FAIL = "FAQ_GET_FAIL"


export const FAQ_DETAIL_START = "FAQ_DETAIL_START"
export const FAQ_DETAIL_SUCCESS = "FAQ_DETAIL_SUCCESS"
export const FAQ_DETAIL_FAIL = "FAQ_DETAIL_FAIL"


export const FAQ_EDIT_START = "FAQ_EDIT_START"
export const FAQ_EDIT_SUCCESS = "FAQ_EDIT_SUCCESS"
export const FAQ_EDIT_FAIL = "FAQ_EDIT_FAIL"


export const FAQ_DELETE_START = "FAQ_DELETE_START"
export const FAQ_DELETE_SUCCESS = "FAQ_DELETE_SUCCESS"
export const FAQ_DELETE_FAIL = "FAQ_DELETE_FAIL"


