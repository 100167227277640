export const COUNTRY_POST_START = "COUNTRY_POST_START"
export const COUNTRY_POST_SUCCESS = "COUNTRY_POST_SUCCESS"
export const COUNTRY_POST_FAIL = "COUNTRY_POST_FAIL"


export const COUNTRY_GET_START = "COUNTRY_GET_START"
export const COUNTRY_GET_SUCCESS = "COUNTRY_GET_SUCCESS"
export const COUNTRY_GET_FAIL = "COUNTRY_GET_FAIL"


export const COUNTRY_DETAIL_START = "COUNTRY_DETAIL_START"
export const COUNTRY_DETAIL_SUCCESS = "COUNTRY_DETAIL_SUCCESS"
export const COUNTRY_DETAIL_FAIL = "COUNTRY_DETAIL_FAIL"


export const COUNTRY_EDIT_START = "COUNTRY_EDIT_START"
export const COUNTRY_EDIT_SUCCESS = "COUNTRY_EDIT_SUCCESS"
export const COUNTRY_EDIT_FAIL = "COUNTRY_EDIT_FAIL"


export const COUNTRY_DELETE_START = "COUNTRY_DELETE_START"
export const COUNTRY_DELETE_SUCCESS = "COUNTRY_DELETE_SUCCESS"
export const COUNTRY_DELETE_FAIL = "COUNTRY_DELETE_FAIL"


