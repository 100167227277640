export const VIEWWISECOURSE_POST_START = "VIEWWISECOURSE_POST_START"
export const VIEWWISECOURSE_POST_SUCCESS = "VIEWWISECOURSE_POST_SUCCESS"
export const VIEWWISECOURSE_POST_FAIL = "VIEWWISECOURSE_POST_FAIL"


export const VIEWWISECOURSE_GET_START = "VIEWWISECOURSE_GET_START"
export const VIEWWISECOURSE_GET_SUCCESS = "VIEWWISECOURSE_GET_SUCCESS"
export const VIEWWISECOURSE_GET_FAIL = "VIEWWISECOURSE_GET_FAIL"


export const VIEWWISECOURSE_DETAIL_START = "VIEWWISECOURSE_DETAIL_START"
export const VIEWWISECOURSE_DETAIL_SUCCESS = "VIEWWISECOURSE_DETAIL_SUCCESS"
export const VIEWWISECOURSE_DETAIL_FAIL = "VIEWWISECOURSE_DETAIL_FAIL"


export const VIEWWISECOURSE_EDIT_START = "VIEWWISECOURSE_EDIT_START"
export const VIEWWISECOURSE_EDIT_SUCCESS = "VIEWWISECOURSE_EDIT_SUCCESS"
export const VIEWWISECOURSE_EDIT_FAIL = "VIEWWISECOURSE_EDIT_FAIL"


export const VIEWWISECOURSE_DELETE_START = "VIEWWISECOURSE_DELETE_START"
export const VIEWWISECOURSE_DELETE_SUCCESS = "VIEWWISECOURSE_DELETE_SUCCESS"
export const VIEWWISECOURSE_DELETE_FAIL = "VIEWWISECOURSE_DELETE_FAIL"


