export const EDUCATIONALPARTNER_POST_START = "EDUCATIONALPARTNER_POST_START"
export const EDUCATIONALPARTNER_POST_SUCCESS = "EDUCATIONALPARTNER_POST_SUCCESS"
export const EDUCATIONALPARTNER_POST_FAIL = "EDUCATIONALPARTNER_POST_FAIL"


export const EDUCATIONALPARTNER_GET_START = "EDUCATIONALPARTNER_GET_START"
export const EDUCATIONALPARTNER_GET_SUCCESS = "EDUCATIONALPARTNER_GET_SUCCESS"
export const EDUCATIONALPARTNER_GET_FAIL = "EDUCATIONALPARTNER_GET_FAIL"


export const EDUCATIONALPARTNER_DETAIL_START = "EDUCATIONALPARTNER_DETAIL_START"
export const EDUCATIONALPARTNER_DETAIL_SUCCESS = "EDUCATIONALPARTNER_DETAIL_SUCCESS"
export const EDUCATIONALPARTNER_DETAIL_FAIL = "EDUCATIONALPARTNER_DETAIL_FAIL"


export const EDUCATIONALPARTNER_EDIT_START = "EDUCATIONALPARTNER_EDIT_START"
export const EDUCATIONALPARTNER_EDIT_SUCCESS = "EDUCATIONALPARTNER_EDIT_SUCCESS"
export const EDUCATIONALPARTNER_EDIT_FAIL = "EDUCATIONALPARTNER_EDIT_FAIL"


export const EDUCATIONALPARTNER_DELETE_START = "EDUCATIONALPARTNER_DELETE_START"
export const EDUCATIONALPARTNER_DELETE_SUCCESS = "EDUCATIONALPARTNER_DELETE_SUCCESS"
export const EDUCATIONALPARTNER_DELETE_FAIL = "EDUCATIONALPARTNER_DELETE_FAIL"


