import * as actionTypes from "./actionType";
import { updateObject } from "../utility";


export const initialState = {
	error: null,
	loading: null,
	publiccontactquestion:[],
	publiccontactquestionDetail:null,
}






const publiccontactquestionGetStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const publiccontactquestionGetSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		publiccontactquestion: action.publiccontactquestion,
	});
};
const publiccontactquestionGetFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const publiccontactquestionPostStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const publiccontactquestionPostSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		publiccontactquestionDetail: action.publiccontactquestionDetail,
	});
};
const publiccontactquestionPostFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const publiccontactquestionEditStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const publiccontactquestionEditSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		publiccontactquestionDetail: action.publiccontactquestionDetail,
	});
};
const publiccontactquestionEditFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const publiccontactquestionDetailStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const publiccontactquestionDetailSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		publiccontactquestionDetail: action.publiccontactquestionDetail,
	});
};
const publiccontactquestionDetailFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const publiccontactquestionDeleteStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const publiccontactquestionDeleteSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		publiccontactquestionDetail: action.publiccontactquestionDetail,
		publiccontactquestion: state.publiccontactquestion.filter((item)=>item.id != action.publiccontactquestionDetail.id),
	});
};
const publiccontactquestionDeleteFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const reducer = (state = initialState, action) => { 
	switch (action.type) { 
		case actionTypes.PUBLICCONTACTQUESTION_GET_START:
			return publiccontactquestionGetStart(state, action);
		case actionTypes.PUBLICCONTACTQUESTION_GET_SUCCESS:
			return publiccontactquestionGetSuccess(state, action);
		case actionTypes.PUBLICCONTACTQUESTION_GET_FAIL:
			return publiccontactquestionGetFail(state, action);






		case actionTypes.PUBLICCONTACTQUESTION_POST_START:
			return publiccontactquestionPostStart(state, action);
		case actionTypes.PUBLICCONTACTQUESTION_POST_SUCCESS:
			return publiccontactquestionPostSuccess(state, action);
		case actionTypes.PUBLICCONTACTQUESTION_POST_FAIL:
			return publiccontactquestionPostFail(state, action);






		case actionTypes.PUBLICCONTACTQUESTION_EDIT_START:
			return publiccontactquestionEditStart(state, action);
		case actionTypes.PUBLICCONTACTQUESTION_EDIT_SUCCESS:
			return publiccontactquestionEditSuccess(state, action);
		case actionTypes.PUBLICCONTACTQUESTION_EDIT_FAIL:
			return publiccontactquestionEditFail(state, action);






		case actionTypes.PUBLICCONTACTQUESTION_DETAIL_START:
			return publiccontactquestionDetailStart(state, action);
		case actionTypes.PUBLICCONTACTQUESTION_DETAIL_SUCCESS:
			return publiccontactquestionDetailSuccess(state, action);
		case actionTypes.PUBLICCONTACTQUESTION_DETAIL_FAIL:
			return publiccontactquestionDetailFail(state, action);






		case actionTypes.PUBLICCONTACTQUESTION_DELETE_START:
			return publiccontactquestionDeleteStart(state, action);
		case actionTypes.PUBLICCONTACTQUESTION_DELETE_SUCCESS:
			return publiccontactquestionDeleteSuccess(state, action);
		case actionTypes.PUBLICCONTACTQUESTION_DELETE_FAIL:
			return publiccontactquestionDeleteFail(state, action);



		default:
			return state;
	}
};
export default reducer;
