import * as actionTypes from "./actionType";
import { updateObject } from "../utility";


export const initialState = {
	error: null,
	loading: null,
	coursecategory:[],
	coursecategoryDetail:null,
}






const coursecategoryGetStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const coursecategoryGetSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		coursecategory: action.coursecategory,
	});
};
const coursecategoryGetFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const coursecategoryPostStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const coursecategoryPostSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		coursecategoryDetail: action.coursecategoryDetail,
	});
};
const coursecategoryPostFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const coursecategoryEditStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const coursecategoryEditSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		coursecategoryDetail: action.coursecategoryDetail,
	});
};
const coursecategoryEditFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const coursecategoryDetailStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const coursecategoryDetailSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		coursecategoryDetail: action.coursecategoryDetail,
	});
};
const coursecategoryDetailFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const coursecategoryDeleteStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const coursecategoryDeleteSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		coursecategoryDetail: action.coursecategoryDetail,
		coursecategory: state.coursecategory.filter((item)=>item.id != action.coursecategoryDetail.id),
	});
};
const coursecategoryDeleteFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const reducer = (state = initialState, action) => { 
	switch (action.type) { 
		case actionTypes.COURSECATEGORY_GET_START:
			return coursecategoryGetStart(state, action);
		case actionTypes.COURSECATEGORY_GET_SUCCESS:
			return coursecategoryGetSuccess(state, action);
		case actionTypes.COURSECATEGORY_GET_FAIL:
			return coursecategoryGetFail(state, action);






		case actionTypes.COURSECATEGORY_POST_START:
			return coursecategoryPostStart(state, action);
		case actionTypes.COURSECATEGORY_POST_SUCCESS:
			return coursecategoryPostSuccess(state, action);
		case actionTypes.COURSECATEGORY_POST_FAIL:
			return coursecategoryPostFail(state, action);






		case actionTypes.COURSECATEGORY_EDIT_START:
			return coursecategoryEditStart(state, action);
		case actionTypes.COURSECATEGORY_EDIT_SUCCESS:
			return coursecategoryEditSuccess(state, action);
		case actionTypes.COURSECATEGORY_EDIT_FAIL:
			return coursecategoryEditFail(state, action);






		case actionTypes.COURSECATEGORY_DETAIL_START:
			return coursecategoryDetailStart(state, action);
		case actionTypes.COURSECATEGORY_DETAIL_SUCCESS:
			return coursecategoryDetailSuccess(state, action);
		case actionTypes.COURSECATEGORY_DETAIL_FAIL:
			return coursecategoryDetailFail(state, action);






		case actionTypes.COURSECATEGORY_DELETE_START:
			return coursecategoryDeleteStart(state, action);
		case actionTypes.COURSECATEGORY_DELETE_SUCCESS:
			return coursecategoryDeleteSuccess(state, action);
		case actionTypes.COURSECATEGORY_DELETE_FAIL:
			return coursecategoryDeleteFail(state, action);



		default:
			return state;
	}
};
export default reducer;
