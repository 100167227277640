import * as actionTypes from "./actionType";
import { updateObject } from "../utility";


export const initialState = {
	error: null,
	loading: null,
	bookingquestion:[],
	bookingquestionDetail:null,
}






const bookingquestionGetStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const bookingquestionGetSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		bookingquestion: action.bookingquestion,
	});
};
const bookingquestionGetFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const bookingquestionPostStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const bookingquestionPostSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		bookingquestionDetail: action.bookingquestionDetail,
	});
};
const bookingquestionPostFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const bookingquestionEditStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const bookingquestionEditSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		bookingquestionDetail: action.bookingquestionDetail,
	});
};
const bookingquestionEditFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const bookingquestionDetailStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const bookingquestionDetailSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		bookingquestionDetail: action.bookingquestionDetail,
	});
};
const bookingquestionDetailFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const bookingquestionDeleteStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const bookingquestionDeleteSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		bookingquestionDetail: action.bookingquestionDetail,
		bookingquestion: state.bookingquestion.filter((item)=>item.id != action.bookingquestionDetail.id),
	});
};
const bookingquestionDeleteFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const reducer = (state = initialState, action) => { 
	switch (action.type) { 
		case actionTypes.BOOKINGQUESTION_GET_START:
			return bookingquestionGetStart(state, action);
		case actionTypes.BOOKINGQUESTION_GET_SUCCESS:
			return bookingquestionGetSuccess(state, action);
		case actionTypes.BOOKINGQUESTION_GET_FAIL:
			return bookingquestionGetFail(state, action);






		case actionTypes.BOOKINGQUESTION_POST_START:
			return bookingquestionPostStart(state, action);
		case actionTypes.BOOKINGQUESTION_POST_SUCCESS:
			return bookingquestionPostSuccess(state, action);
		case actionTypes.BOOKINGQUESTION_POST_FAIL:
			return bookingquestionPostFail(state, action);






		case actionTypes.BOOKINGQUESTION_EDIT_START:
			return bookingquestionEditStart(state, action);
		case actionTypes.BOOKINGQUESTION_EDIT_SUCCESS:
			return bookingquestionEditSuccess(state, action);
		case actionTypes.BOOKINGQUESTION_EDIT_FAIL:
			return bookingquestionEditFail(state, action);






		case actionTypes.BOOKINGQUESTION_DETAIL_START:
			return bookingquestionDetailStart(state, action);
		case actionTypes.BOOKINGQUESTION_DETAIL_SUCCESS:
			return bookingquestionDetailSuccess(state, action);
		case actionTypes.BOOKINGQUESTION_DETAIL_FAIL:
			return bookingquestionDetailFail(state, action);






		case actionTypes.BOOKINGQUESTION_DELETE_START:
			return bookingquestionDeleteStart(state, action);
		case actionTypes.BOOKINGQUESTION_DELETE_SUCCESS:
			return bookingquestionDeleteSuccess(state, action);
		case actionTypes.BOOKINGQUESTION_DELETE_FAIL:
			return bookingquestionDeleteFail(state, action);



		default:
			return state;
	}
};
export default reducer;
