import * as actionTypes from "./actionType";
import { updateObject } from "../utility";


export const initialState = {
	error: null,
	loading: null,
	booking:[],
	bookingDetail:null,
}






const bookingGetStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const bookingGetSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		booking: action.booking,
	});
};
const bookingGetFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const bookingPostStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const bookingPostSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		bookingDetail: action.bookingDetail,
	});
};
const bookingPostFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const bookingEditStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const bookingEditSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		bookingDetail: action.bookingDetail,
	});
};
const bookingEditFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const bookingDetailStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const bookingDetailSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		bookingDetail: action.bookingDetail,
	});
};
const bookingDetailFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const bookingDeleteStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const bookingDeleteSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		bookingDetail: action.bookingDetail,
		booking: state.booking.filter((item)=>item.id != action.bookingDetail.id),
	});
};
const bookingDeleteFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const reducer = (state = initialState, action) => { 
	switch (action.type) { 
		case actionTypes.BOOKING_GET_START:
			return bookingGetStart(state, action);
		case actionTypes.BOOKING_GET_SUCCESS:
			return bookingGetSuccess(state, action);
		case actionTypes.BOOKING_GET_FAIL:
			return bookingGetFail(state, action);






		case actionTypes.BOOKING_POST_START:
			return bookingPostStart(state, action);
		case actionTypes.BOOKING_POST_SUCCESS:
			return bookingPostSuccess(state, action);
		case actionTypes.BOOKING_POST_FAIL:
			return bookingPostFail(state, action);






		case actionTypes.BOOKING_EDIT_START:
			return bookingEditStart(state, action);
		case actionTypes.BOOKING_EDIT_SUCCESS:
			return bookingEditSuccess(state, action);
		case actionTypes.BOOKING_EDIT_FAIL:
			return bookingEditFail(state, action);






		case actionTypes.BOOKING_DETAIL_START:
			return bookingDetailStart(state, action);
		case actionTypes.BOOKING_DETAIL_SUCCESS:
			return bookingDetailSuccess(state, action);
		case actionTypes.BOOKING_DETAIL_FAIL:
			return bookingDetailFail(state, action);






		case actionTypes.BOOKING_DELETE_START:
			return bookingDeleteStart(state, action);
		case actionTypes.BOOKING_DELETE_SUCCESS:
			return bookingDeleteSuccess(state, action);
		case actionTypes.BOOKING_DELETE_FAIL:
			return bookingDeleteFail(state, action);



		default:
			return state;
	}
};
export default reducer;
