export const SUBSCRIBER_POST_START = "SUBSCRIBER_POST_START"
export const SUBSCRIBER_POST_SUCCESS = "SUBSCRIBER_POST_SUCCESS"
export const SUBSCRIBER_POST_FAIL = "SUBSCRIBER_POST_FAIL"


export const SUBSCRIBER_GET_START = "SUBSCRIBER_GET_START"
export const SUBSCRIBER_GET_SUCCESS = "SUBSCRIBER_GET_SUCCESS"
export const SUBSCRIBER_GET_FAIL = "SUBSCRIBER_GET_FAIL"


export const SUBSCRIBER_DETAIL_START = "SUBSCRIBER_DETAIL_START"
export const SUBSCRIBER_DETAIL_SUCCESS = "SUBSCRIBER_DETAIL_SUCCESS"
export const SUBSCRIBER_DETAIL_FAIL = "SUBSCRIBER_DETAIL_FAIL"


export const SUBSCRIBER_EDIT_START = "SUBSCRIBER_EDIT_START"
export const SUBSCRIBER_EDIT_SUCCESS = "SUBSCRIBER_EDIT_SUCCESS"
export const SUBSCRIBER_EDIT_FAIL = "SUBSCRIBER_EDIT_FAIL"


export const SUBSCRIBER_DELETE_START = "SUBSCRIBER_DELETE_START"
export const SUBSCRIBER_DELETE_SUCCESS = "SUBSCRIBER_DELETE_SUCCESS"
export const SUBSCRIBER_DELETE_FAIL = "SUBSCRIBER_DELETE_FAIL"


