export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";



export const GET_SETTING_START = "GET_SETTING_START";
export const GET_SETTING_SUCCESS = "GET_SETTING_SUCCESS";
export const GET_SETTING_FAIL = "GET_SETTING_FAIL";



export const POST_SETTING_START = "POST_SETTING_START";
export const POST_SETTING_SUCCESS = "POST_SETTING_SUCCESS";
export const POST_SETTING_FAIL = "POST_SETTING_FAIL";


export const UPDATE_TRAINING_INFO = 'UPDATE_TRAINING_INFO';
export const CLEAR_TRAINING_INFO = 'CLEAR_TRAINING_INFO';












export const BOOKTYPE_GET_START = "BOOKTYPE_GET_START";
export const BOOKTYPE_GET_SUCCESS = "BOOKTYPE_GET_SUCCESS";
export const BOOKTYPE_GET_FAIL = "BOOKTYPE_GET_FAIL";


export const BOOKTYPE_POST_START = "BOOKTYPE_POST_START";
export const BOOKTYPE_POST_SUCCESS = "BOOKTYPE_POST_SUCCESS";
export const BOOKTYPE_POST_FAIL = "BOOKTYPE_POST_FAIL";

export const BOOKTYPE_EDIT_START = "BOOKTYPE_EDIT_START";
export const BOOKTYPE_EDIT_SUCCESS = "BOOKTYPE_EDIT_SUCCESS";
export const BOOKTYPE_EDIT_FAIL = "BOOKTYPE_EDIT_FAIL";








export const PERMISSION_GET_START = "PERMISSION_GET_START";
export const PERMISSION_GET_SUCCESS = "PERMISSION_GET_SUCCESS";
export const PERMISSION_GET_FAIL = "PERMISSION_GET_FAIL";







export const MEMBER_GET_START = "MEMBER_GET_START";
export const MEMBER_GET_SUCCESS = "MEMBER_GET_SUCCESS";
export const MEMBER_GET_FAIL = "MEMBER_GET_FAIL";


export const MEMBER_POST_START = "MEMBER_POST_START";
export const MEMBER_POST_SUCCESS = "MEMBER_POST_SUCCESS";
export const MEMBER_POST_FAIL = "MEMBER_POST_FAIL";

export const MEMBER_EDIT_START = "MEMBER_EDIT_START";
export const MEMBER_EDIT_SUCCESS = "MEMBER_EDIT_SUCCESS";
export const MEMBER_EDIT_FAIL = "MEMBER_EDIT_FAIL";














export const MEMBERTYPE_GET_START = "MEMBERTYPE_GET_START";
export const MEMBERTYPE_GET_SUCCESS = "MEMBERTYPE_GET_SUCCESS";
export const MEMBERTYPE_GET_FAIL = "MEMBERTYPE_GET_FAIL";


export const MEMBERTYPE_POST_START = "MEMBERTYPE_POST_START";
export const MEMBERTYPE_POST_SUCCESS = "MEMBERTYPE_POST_SUCCESS";
export const MEMBERTYPE_POST_FAIL = "MEMBERTYPE_POST_FAIL";

export const MEMBERTYPE_EDIT_START = "MEMBERTYPE_EDIT_START";
export const MEMBERTYPE_EDIT_SUCCESS = "MEMBERTYPE_EDIT_SUCCESS";
export const MEMBERTYPE_EDIT_FAIL = "BOOKTYPE_EDIT_FAIL";




export const BOOK_POST_START = "BOOK_POST_START";
export const BOOK_POST_SUCCESS = "BOOK_POST_SUCCESS";
export const BOOK_POST_FAIL = "BOOK_POST_FAIL";

export const BOOK_GET_START = "BOOK_GET_START";
export const BOOK_GET_SUCCESS = "BOOK_GET_SUCCESS";
export const BOOK_GET_FAIL = "BOOK_GET_FAIL";

export const BOOK_EDIT_START = "BOOK_EDIT_START";
export const BOOK_EDIT_SUCCESS = "BOOK_EDIT_SUCCESS";
export const BOOK_EDIT_FAIL = "BOOK_EDIT_FAIL";











export const BOOK_POST_CATEGORY_START = "BOOK_POST_CATEGORY_START";
export const BOOK_POST_CATEGORY_SUCCESS = "BOOK_POST_CATEGORY_SUCCESS";
export const BOOK_POST_CATEGORY_FAIL = "BOOK_POST_CATEGORY_FAIL";

export const BOOK_GET_CATEGORY_START = "BOOK_GET_CATEGORY_START";
export const BOOK_GET_CATEGORY_SUCCESS = "BOOK_GET_CATEGORY_SUCCESS";
export const BOOK_GET_CATEGORY_FAIL = "BOOK_GET_CATEGORY_FAIL";

export const BOOK_EDIT_CATEGORY_START = "BOOK_EDIT_CATEGORY_START";
export const BOOK_EDIT_CATEGORY_SUCCESS = "BOOK_EDIT_CATEGORY_SUCCESS";
export const BOOK_EDIT_CATEGORY_FAIL = "BOOK_EDIT_CATEGORY_FAIL";
export const BOOK_DELETE_CATEGORY_FAIL = "BOOK_DELETE_CATEGORY_FAIL";









export const BOOK_POST_RACK_START = "BOOK_POST_RACK_START";
export const BOOK_POST_RACK_SUCCESS = "BOOK_POST_RACK_SUCCESS";
export const BOOK_POST_RACK_FAIL = "BOOK_POST_RACK_FAIL";

export const BOOK_GET_RACK_START = "BOOK_GET_RACK_START";
export const BOOK_GET_RACK_SUCCESS = "BOOK_GET_RACK_SUCCESS";
export const BOOK_GET_RACK_FAIL = "BOOK_GET_RACK_FAIL";

export const BOOK_EDIT_RACK_START = "BOOK_EDIT_RACK_START";
export const BOOK_EDIT_RACK_SUCCESS = "BOOK_EDIT_RACK_SUCCESS";
export const BOOK_EDIT_RACK_FAIL = "BOOK_EDIT_RACK_FAIL";
export const BOOK_DELETE_RACK_FAIL = "BOOK_DELETE_RACK_FAIL";



export const BOOK_POST_ROOM_START = "BOOK_POST_ROOM_START";
export const BOOK_POST_ROOM_SUCCESS = "BOOK_POST_ROOM_SUCCESS";
export const BOOK_POST_ROOM_FAIL = "BOOK_POST_ROOM_FAIL";

export const BOOK_GET_ROOM_START = "BOOK_GET_ROOM_START";
export const BOOK_GET_ROOM_SUCCESS = "BOOK_GET_ROOM_SUCCESS";
export const BOOK_GET_ROOM_FAIL = "BOOK_GET_ROOM_FAIL";

export const BOOK_EDIT_ROOM_START = "BOOK_EDIT_ROOM_START";
export const BOOK_EDIT_ROOM_SUCCESS = "BOOK_EDIT_ROOM_SUCCESS";
export const BOOK_EDIT_ROOM_FAIL = "BOOK_EDIT_ROOM_FAIL";
export const BOOK_DELETE_ROOM_FAIL = "BOOK_DELETE_ROOM_FAIL";









export const BOOK_POST_EDITION_START = "BOOK_POST_EDITION_START";
export const BOOK_POST_EDITION_SUCCESS = "BOOK_POST_EDITION_SUCCESS";
export const BOOK_POST_EDITION_FAIL = "BOOK_POST_EDITION_FAIL";

export const BOOK_GET_EDITION_START = "BOOK_GET_EDITION_START";
export const BOOK_GET_EDITION_SUCCESS = "BOOK_GET_EDITION_SUCCESS";
export const BOOK_GET_EDITION_FAIL = "BOOK_GET_EDITION_FAIL";

export const BOOK_EDIT_EDITION_START = "BOOK_EDIT_EDITION_START";
export const BOOK_EDIT_EDITION_SUCCESS = "BOOK_EDIT_EDITION_SUCCESS";
export const BOOK_EDIT_EDITION_FAIL = "BOOK_EDIT_EDITION_FAIL";


export const BOOK_POST_SUBCATEGORY_START = "BOOK_POST_SUBCATEGORY_START";
export const BOOK_POST_SUBCATEGORY_SUCCESS = "BOOK_POST_SUBCATEGORY_SUCCESS";
export const BOOK_POST_SUBCATEGORY_FAIL = "BOOK_POST_SUBCATEGORY_FAIL";

export const BOOK_GET_SUBCATEGORY_START = "BOOK_GET_SUBCATEGORY_START";
export const BOOK_GET_SUBCATEGORY_SUCCESS = "BOOK_GET_SUBCATEGORY_SUCCESS";
export const BOOK_GET_SUBCATEGORY_FAIL = "BOOK_GET_SUBCATEGORY_FAIL";

export const BOOK_EDIT_SUBCATEGORY_START = "BOOK_EDIT_SUBCATEGORY_START";
export const BOOK_EDIT_SUBCATEGORY_SUCCESS = "BOOK_EDIT_SUBCATEGORY_SUCCESS";
export const BOOK_EDIT_SUBCATEGORY_FAIL = "BOOK_EDIT_SUBCATEGORY_FAIL";




export const BOOK_DETAIL_EDITION_SUCCESS = "BOOK_DETAIL_EDITION_SUCCESS";





export const BOOK_ENTRY_POST_START = "BOOK_ENTRY_POST_START";
export const BOOK_ENTRY_POST_SUCCESS = "BOOK_ENTRY_POST_SUCCESS";
export const BOOK_ENTRY_POST_FAIL = "BOOK_ENTRY_POST_FAIL";



export const GET_BOOK_BOOKENTRY_DETAIL_START = "GET_BOOK_BOOKENTRY_DETAIL_START"
export const GET_BOOK_BOOKENTRY_DETAIL_SUCCESS = "GET_BOOK_BOOKENTRY_DETAIL_SUCCESS"
export const GET_BOOK_BOOKENTRY_DETAIL_FAIL = "GET_BOOK_BOOKENTRY_DETAIL_FAIL"



export const GET_BOOKENTRY_BATCH_START = "GET_BOOKENTRY_BATCH_START"
export const GET_BOOKENTRY_BATCH_SUCCESS = "GET_BOOKENTRY_BATCH_SUCCESS"
export const GET_BOOKENTRY_BATCHES_SUCCESS = "GET_BOOKENTRY_BATCHES_SUCCESS"
export const GET_BOOKENTRY_BATCH_FAIL = "GET_BOOKENTRY_BATCH_FAIL"












export const BOOK_RACK_GET_START = "BOOK_RACK_GET_START";
export const BOOK_RACK_GET_SUCCESS = "BOOK_RACK_GET_SUCCESS";
export const BOOK_RACK_GET_FAIL = "BOOK_RACK_GET_FAIL";




export const BOOK_ROOM_GET_START = "BOOK_ROOM_GET_START";
export const BOOK_ROOM_GET_SUCCESS = "BOOK_ROOM_GET_SUCCESS";
export const BOOK_ROOM_GET_FAIL = "BOOK_ROOM_GET_FAIL";






export const BOOK_GET_AUTHOR_START = "BOOK_GET_AUTHOR_START";
export const BOOK_GET_AUTHOR_SUCCESS = "BOOK_GET_AUTHOR_SUCCESS";
export const BOOK_GET_AUTHOR_FAIL = "BOOK_GET_AUTHOR_FAIL";



export const BOOK_GET_PUBLICATION_START = "BOOK_GET_PUBLICATION_START";
export const BOOK_GET_PUBLICATION_SUCCESS = "BOOK_GET_PUBLICATION_SUCCESS";
export const BOOK_GET_PUBLICATION_FAIL = "BOOK_GET_PUBLICATION_FAIL";