import * as actionTypes from "./actionType";
import { updateObject } from "../utility";


export const initialState = {
	error: null,
	loading: null,
	subscriber:[],
	subscriberDetail:null,
}






const subscriberGetStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const subscriberGetSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		subscriber: action.subscriber,
	});
};
const subscriberGetFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const subscriberPostStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const subscriberPostSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		subscriberDetail: action.subscriberDetail,
	});
};
const subscriberPostFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const subscriberEditStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const subscriberEditSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		subscriberDetail: action.subscriberDetail,
	});
};
const subscriberEditFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const subscriberDetailStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const subscriberDetailSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		subscriberDetail: action.subscriberDetail,
	});
};
const subscriberDetailFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const subscriberDeleteStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const subscriberDeleteSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		subscriberDetail: action.subscriberDetail,
		subscriber: state.subscriber.filter((item)=>item.id != action.subscriberDetail.id),
	});
};
const subscriberDeleteFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const reducer = (state = initialState, action) => { 
	switch (action.type) { 
		case actionTypes.SUBSCRIBER_GET_START:
			return subscriberGetStart(state, action);
		case actionTypes.SUBSCRIBER_GET_SUCCESS:
			return subscriberGetSuccess(state, action);
		case actionTypes.SUBSCRIBER_GET_FAIL:
			return subscriberGetFail(state, action);






		case actionTypes.SUBSCRIBER_POST_START:
			return subscriberPostStart(state, action);
		case actionTypes.SUBSCRIBER_POST_SUCCESS:
			return subscriberPostSuccess(state, action);
		case actionTypes.SUBSCRIBER_POST_FAIL:
			return subscriberPostFail(state, action);






		case actionTypes.SUBSCRIBER_EDIT_START:
			return subscriberEditStart(state, action);
		case actionTypes.SUBSCRIBER_EDIT_SUCCESS:
			return subscriberEditSuccess(state, action);
		case actionTypes.SUBSCRIBER_EDIT_FAIL:
			return subscriberEditFail(state, action);






		case actionTypes.SUBSCRIBER_DETAIL_START:
			return subscriberDetailStart(state, action);
		case actionTypes.SUBSCRIBER_DETAIL_SUCCESS:
			return subscriberDetailSuccess(state, action);
		case actionTypes.SUBSCRIBER_DETAIL_FAIL:
			return subscriberDetailFail(state, action);






		case actionTypes.SUBSCRIBER_DELETE_START:
			return subscriberDeleteStart(state, action);
		case actionTypes.SUBSCRIBER_DELETE_SUCCESS:
			return subscriberDeleteSuccess(state, action);
		case actionTypes.SUBSCRIBER_DELETE_FAIL:
			return subscriberDeleteFail(state, action);



		default:
			return state;
	}
};
export default reducer;
