export const PUBLICCONTACTQUESTION_POST_START = "PUBLICCONTACTQUESTION_POST_START"
export const PUBLICCONTACTQUESTION_POST_SUCCESS = "PUBLICCONTACTQUESTION_POST_SUCCESS"
export const PUBLICCONTACTQUESTION_POST_FAIL = "PUBLICCONTACTQUESTION_POST_FAIL"


export const PUBLICCONTACTQUESTION_GET_START = "PUBLICCONTACTQUESTION_GET_START"
export const PUBLICCONTACTQUESTION_GET_SUCCESS = "PUBLICCONTACTQUESTION_GET_SUCCESS"
export const PUBLICCONTACTQUESTION_GET_FAIL = "PUBLICCONTACTQUESTION_GET_FAIL"


export const PUBLICCONTACTQUESTION_DETAIL_START = "PUBLICCONTACTQUESTION_DETAIL_START"
export const PUBLICCONTACTQUESTION_DETAIL_SUCCESS = "PUBLICCONTACTQUESTION_DETAIL_SUCCESS"
export const PUBLICCONTACTQUESTION_DETAIL_FAIL = "PUBLICCONTACTQUESTION_DETAIL_FAIL"


export const PUBLICCONTACTQUESTION_EDIT_START = "PUBLICCONTACTQUESTION_EDIT_START"
export const PUBLICCONTACTQUESTION_EDIT_SUCCESS = "PUBLICCONTACTQUESTION_EDIT_SUCCESS"
export const PUBLICCONTACTQUESTION_EDIT_FAIL = "PUBLICCONTACTQUESTION_EDIT_FAIL"


export const PUBLICCONTACTQUESTION_DELETE_START = "PUBLICCONTACTQUESTION_DELETE_START"
export const PUBLICCONTACTQUESTION_DELETE_SUCCESS = "PUBLICCONTACTQUESTION_DELETE_SUCCESS"
export const PUBLICCONTACTQUESTION_DELETE_FAIL = "PUBLICCONTACTQUESTION_DELETE_FAIL"


