export const ABOUTUS_POST_START = "ABOUTUS_POST_START"
export const ABOUTUS_POST_SUCCESS = "ABOUTUS_POST_SUCCESS"
export const ABOUTUS_POST_FAIL = "ABOUTUS_POST_FAIL"


export const ABOUTUS_GET_START = "ABOUTUS_GET_START"
export const ABOUTUS_GET_SUCCESS = "ABOUTUS_GET_SUCCESS"
export const ABOUTUS_GET_FAIL = "ABOUTUS_GET_FAIL"


export const ABOUTUS_DETAIL_START = "ABOUTUS_DETAIL_START"
export const ABOUTUS_DETAIL_SUCCESS = "ABOUTUS_DETAIL_SUCCESS"
export const ABOUTUS_DETAIL_FAIL = "ABOUTUS_DETAIL_FAIL"


export const ABOUTUS_EDIT_START = "ABOUTUS_EDIT_START"
export const ABOUTUS_EDIT_SUCCESS = "ABOUTUS_EDIT_SUCCESS"
export const ABOUTUS_EDIT_FAIL = "ABOUTUS_EDIT_FAIL"


export const ABOUTUS_DELETE_START = "ABOUTUS_DELETE_START"
export const ABOUTUS_DELETE_SUCCESS = "ABOUTUS_DELETE_SUCCESS"
export const ABOUTUS_DELETE_FAIL = "ABOUTUS_DELETE_FAIL"


