import * as actionTypes from "./actionType";
import { updateObject } from "../utility";


export const initialState = {
	error: null,
	loading: null,
	educationalpartner:[],
	educationalpartnerDetail:null,
}






const educationalpartnerGetStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const educationalpartnerGetSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		educationalpartner: action.educationalpartner,
	});
};
const educationalpartnerGetFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const educationalpartnerPostStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const educationalpartnerPostSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		educationalpartnerDetail: action.educationalpartnerDetail,
	});
};
const educationalpartnerPostFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const educationalpartnerEditStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const educationalpartnerEditSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		educationalpartnerDetail: action.educationalpartnerDetail,
	});
};
const educationalpartnerEditFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const educationalpartnerDetailStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const educationalpartnerDetailSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		educationalpartnerDetail: action.educationalpartnerDetail,
	});
};
const educationalpartnerDetailFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const educationalpartnerDeleteStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};
const educationalpartnerDeleteSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		educationalpartnerDetail: action.educationalpartnerDetail,
		educationalpartner: state.educationalpartner.filter((item)=>item.id != action.educationalpartnerDetail.id),
	});
};
const educationalpartnerDeleteFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};






const reducer = (state = initialState, action) => { 
	switch (action.type) { 
		case actionTypes.EDUCATIONALPARTNER_GET_START:
			return educationalpartnerGetStart(state, action);
		case actionTypes.EDUCATIONALPARTNER_GET_SUCCESS:
			return educationalpartnerGetSuccess(state, action);
		case actionTypes.EDUCATIONALPARTNER_GET_FAIL:
			return educationalpartnerGetFail(state, action);






		case actionTypes.EDUCATIONALPARTNER_POST_START:
			return educationalpartnerPostStart(state, action);
		case actionTypes.EDUCATIONALPARTNER_POST_SUCCESS:
			return educationalpartnerPostSuccess(state, action);
		case actionTypes.EDUCATIONALPARTNER_POST_FAIL:
			return educationalpartnerPostFail(state, action);






		case actionTypes.EDUCATIONALPARTNER_EDIT_START:
			return educationalpartnerEditStart(state, action);
		case actionTypes.EDUCATIONALPARTNER_EDIT_SUCCESS:
			return educationalpartnerEditSuccess(state, action);
		case actionTypes.EDUCATIONALPARTNER_EDIT_FAIL:
			return educationalpartnerEditFail(state, action);






		case actionTypes.EDUCATIONALPARTNER_DETAIL_START:
			return educationalpartnerDetailStart(state, action);
		case actionTypes.EDUCATIONALPARTNER_DETAIL_SUCCESS:
			return educationalpartnerDetailSuccess(state, action);
		case actionTypes.EDUCATIONALPARTNER_DETAIL_FAIL:
			return educationalpartnerDetailFail(state, action);






		case actionTypes.EDUCATIONALPARTNER_DELETE_START:
			return educationalpartnerDeleteStart(state, action);
		case actionTypes.EDUCATIONALPARTNER_DELETE_SUCCESS:
			return educationalpartnerDeleteSuccess(state, action);
		case actionTypes.EDUCATIONALPARTNER_DELETE_FAIL:
			return educationalpartnerDeleteFail(state, action);



		default:
			return state;
	}
};
export default reducer;
