import * as actionTypes from './actionType'
import { updateObject } from '../utility'

export const initialState = {
  error: null,
  loading: null,
  service: [],
  serviceDetail: null,
}

const serviceGetStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const serviceGetSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    service: action.service,
  })
}
const serviceGetFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const servicePostStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const servicePostSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    serviceDetail: action.serviceDetail,
  })
}
const servicePostFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const serviceEditStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const serviceEditSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    serviceDetail: action.serviceDetail,
  })
}
const serviceEditFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const serviceDetailStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const serviceDetailSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    serviceDetail: action.serviceDetail,
  })
}
const serviceDetailFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const serviceDeleteStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  })
}
const serviceDeleteSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    serviceDetail: action.serviceDetail,
    service: state.service.filter((item) => item.id != action.serviceDetail.id),
  })
}
const serviceDeleteFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SERVICE_GET_START:
      return serviceGetStart(state, action)
    case actionTypes.SERVICE_GET_SUCCESS:
      return serviceGetSuccess(state, action)
    case actionTypes.SERVICE_GET_FAIL:
      return serviceGetFail(state, action)

    case actionTypes.SERVICE_POST_START:
      return servicePostStart(state, action)
    case actionTypes.SERVICE_POST_SUCCESS:
      return servicePostSuccess(state, action)
    case actionTypes.SERVICE_POST_FAIL:
      return servicePostFail(state, action)

    case actionTypes.SERVICE_EDIT_START:
      return serviceEditStart(state, action)
    case actionTypes.SERVICE_EDIT_SUCCESS:
      return serviceEditSuccess(state, action)
    case actionTypes.SERVICE_EDIT_FAIL:
      return serviceEditFail(state, action)

    case actionTypes.SERVICE_DETAIL_START:
      return serviceDetailStart(state, action)
    case actionTypes.SERVICE_DETAIL_SUCCESS:
      return serviceDetailSuccess(state, action)
    case actionTypes.SERVICE_DETAIL_FAIL:
      return serviceDetailFail(state, action)

    case actionTypes.SERVICE_DELETE_START:
      return serviceDeleteStart(state, action)
    case actionTypes.SERVICE_DELETE_SUCCESS:
      return serviceDeleteSuccess(state, action)
    case actionTypes.SERVICE_DELETE_FAIL:
      return serviceDeleteFail(state, action)

    default:
      return state
  }
}
export default reducer
