export const DESTINATION_POST_START = "DESTINATION_POST_START"
export const DESTINATION_POST_SUCCESS = "DESTINATION_POST_SUCCESS"
export const DESTINATION_POST_FAIL = "DESTINATION_POST_FAIL"


export const DESTINATION_GET_START = "DESTINATION_GET_START"
export const DESTINATION_GET_SUCCESS = "DESTINATION_GET_SUCCESS"
export const DESTINATION_GET_FAIL = "DESTINATION_GET_FAIL"


export const DESTINATION_DETAIL_START = "DESTINATION_DETAIL_START"
export const DESTINATION_DETAIL_SUCCESS = "DESTINATION_DETAIL_SUCCESS"
export const DESTINATION_DETAIL_FAIL = "DESTINATION_DETAIL_FAIL"


export const DESTINATION_EDIT_START = "DESTINATION_EDIT_START"
export const DESTINATION_EDIT_SUCCESS = "DESTINATION_EDIT_SUCCESS"
export const DESTINATION_EDIT_FAIL = "DESTINATION_EDIT_FAIL"


export const DESTINATION_DELETE_START = "DESTINATION_DELETE_START"
export const DESTINATION_DELETE_SUCCESS = "DESTINATION_DELETE_SUCCESS"
export const DESTINATION_DELETE_FAIL = "DESTINATION_DELETE_FAIL"


