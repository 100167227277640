import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

export const initialState = {
  token: null,
  error: null,
  loading: false,
  username: null,
  userId: null,
  successAuth: false,
  traningDate: {},
  user: {}
};

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.user.token,
    username: action.user.username,
    userId: action.user.userId,
    user: action.user,
    error: null,
    successAuth: true,
    loading: false
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    token: null,
    user:{},
    successAuth: false,
  });
};

const authLogout = (state, action) => {
     //console.log('saurav thakur')

  return updateObject(state, {
    token: null,
    user:{},
    error: null,
    loading: false,
    username: null,
    userId: null,
    successAuth: false,
    traningDate: {},
  });
};

const updateTrainingInfo = (state, action) => {
  //console.log(action.data)
  return updateObject(state, {
    traningDate: action.data,
  });
};
export const clearTrainingInfo = (state, action) => {
  return updateObject(state, {
    traningDate: {},
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.UPDATE_TRAINING_INFO:
      return updateTrainingInfo(state, action);
    case actionTypes.CLEAR_TRAINING_INFO:
      return clearTrainingInfo(state, action)
    default:
      return state;
  }
};

export default reducer;
